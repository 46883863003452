import Image from 'next/image'
import React, { Dispatch, SetStateAction } from 'react'

import { UseFormReturn } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { AuthData } from '@/lib/schemes/auth'

import { useSignInDialog } from '@/hooks/auth/forms/useSignInDialog'
import { ActionType, LoginOption } from '@/hooks/auth/useAccountLoginDetails'

import Button from '@/components/Button'
import Input from '@/components/Input'
import Label from '@/components/Label'
import PhoneInput from '@/components/PhoneInput'
import { Dialog, SpecificDialogProps } from '@/components/dialogs/Dialog'
import Form from '@/components/form/Form'
import FormField from '@/components/form/FormField'

interface SignInDialogProps extends SpecificDialogProps {
  openConfirmEmailModal: () => void
  openConfirmPhoneModal: () => void
  setAuthData: Dispatch<SetStateAction<AuthData | undefined>>
  action?: ActionType
  currentLoginData?: LoginOption | null
}

export const SignInDialog = ({
  isOpen,
  action,
  currentLoginData,
  onClose,
  setAuthData,
  openConfirmEmailModal,
  openConfirmPhoneModal
}: SignInDialogProps) => {
  const { formatMessage } = useIntl()

  const { form, isLoading, onSubmit, handleCloseModal } = useSignInDialog({
    onClose,
    openConfirmEmailModal,
    openConfirmPhoneModal,
    setAuthData,
    action,
    currentLoginData
  })

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors }
  } = form

  const isNewPhone = action === 'adding' || action === 'editing'
  const mode = watch('mode')

  return (
    <Dialog
      {...{
        isOpen,
        onClose: handleCloseModal,
        title: formatMessage({
          id: isNewPhone ? 'dialog.signIn.new' : 'dialog.signIn.title',
          defaultMessage: isNewPhone ? 'Новый способ авторизации' : 'Вход'
        })
      }}
    >
      <div className="flex flex-col gap-6">
        <Form className="gap-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-3">
            {mode === 'email' ? (
              <FormField className="gap-2.5" error={errors.email}>
                <Label>
                  <FormattedMessage
                    {...{
                      id: 'email',
                      defaultMessage: 'Email'
                    }}
                  />
                </Label>
                <Input
                  className="h-11"
                  type="email"
                  inputMode="email"
                  placeholder="Email"
                  {...register('email')}
                />
              </FormField>
            ) : (
              <LoginWithPhone form={form} />
            )}
            <LoginMethodChange form={form} />
            {false && (
              <>
                <div className="grid grid-cols-3 gap-2">
                  <Button className="!py-1" variant="secondary">
                    <Image
                      className="h-8 w-8 flex-none"
                      alt="facebook"
                      height={32}
                      src="/images/facebook-32.png"
                      unoptimized
                      width={32}
                    />
                  </Button>
                  <Button className="!py-1" variant="secondary">
                    <Image
                      className="h-6 w-6 flex-none"
                      alt="google"
                      height={32}
                      src="/images/google-32.svg"
                      unoptimized
                      width={32}
                    />
                  </Button>
                  <Button className="!py-1" variant="secondary">
                    <Image
                      className="h-8 w-8 flex-none"
                      alt="apple"
                      height={32}
                      src="/images/apple-32.svg"
                      unoptimized
                      width={32}
                    />
                  </Button>
                </div>
              </>
            )}
          </div>
          <Button
            className="min-h-11"
            {...{
              isLoading,
              variant: isNewPhone ? 'secondary' : 'primary'
            }}
          >
            {isNewPhone ? (
              <FormattedMessage
                {...{
                  id: 'button.continue',
                  defaultMessage: 'Продолжить'
                }}
              />
            ) : (
              <FormattedMessage
                {...{
                  id: 'button.login',
                  defaultMessage: 'Войти'
                }}
              />
            )}
          </Button>
        </Form>
      </div>
    </Dialog>
  )
}

const LoginWithPhone = ({ form }: { form: UseFormReturn<AuthData> }) => {
  const {
    register,
    watch,
    formState: { errors }
  } = form
  return (
    <FormField className="gap-2.5" error={errors.phone || errors.countryCode}>
      <Label>
        <FormattedMessage
          {...{
            id: 'phone',
            defaultMessage: 'Номер телефона'
          }}
        />
      </Label>
      <PhoneInput
        className="h-11"
        buttonClassName="!h-[38px]"
        inputClassName="!h-[42px]"
        inputProps={register('phone')}
        countrySelectProps={{
          ...register('countryCode'),
          value: watch('countryCode')
        }}
      />
    </FormField>
  )
}

const LoginMethodChange = ({ form }: { form: UseFormReturn<AuthData> }) => {
  const { watch, setValue } = form

  const mode = watch('mode')

  return (
    <>
      <div className="flex items-center gap-x-5">
        <div className="h-[1px] flex-auto bg-slate-300"></div>
        <span className="text-x-sm leading-x-sm">
          <FormattedMessage
            {...{
              id: 'or',
              defaultMessage: 'или'
            }}
          />
        </span>
        <div className="h-[1px] flex-auto bg-slate-300"></div>
      </div>
      {mode === 'email' ? (
        <Button
          className="w-full"
          onClick={() => setValue('mode', 'phone')}
          tag="div"
          variant="secondary"
        >
          <FormattedMessage
            {...{
              id: 'button.loginWithPhone',
              defaultMessage: 'Войти по номеру телефона'
            }}
          />
        </Button>
      ) : (
        <Button
          className="w-full"
          onClick={() => setValue('mode', 'email')}
          tag="div"
          variant="secondary"
        >
          <FormattedMessage
            {...{
              id: 'button.loginWithEmail',
              defaultMessage: 'Войти через email'
            }}
          />
        </Button>
      )}
    </>
  )
}
