import { ZodType, z } from 'zod'

import { CryptoCurrency, PaymentMethod, WithdrawalMethod } from '@/lib/types'
import { isValidPhone } from '@/lib/utils'

import { validate } from 'bitcoin-address-validation'

export interface ReplenishData {
  advance: number
  paymentMethod: number | string | null
}

export interface WithdrawData {
  amount: number
  account: string | null
  method: WithdrawalMethod | null
  currency: CryptoCurrency
}

export interface SwapData {
  amount: number
  from: CryptoCurrency
  to: CryptoCurrency
}

export interface DeviceOrderData {
  orderAmount: number
  advance: number | null
  paymentMethod?: PaymentMethod | null
  useBonusBalance: boolean
}

export interface DeviceSellData {
  price?: number
  instant: boolean
  count: number
}

export interface SupportData {
  category: string
  title: string
  text: string
}

export interface GenerateLinkData {
  utm_source: string | null
  utm_medium: string | null
  utm_campaign: string | null
  utm_content: string | null
  utm_term: string | null
}

export interface Promocode {
  code?: string
  hasPromocode: boolean
  isPromocodeApplied: boolean
}

export interface PartnerID {
  partnerId: string
}

export interface PhoneData {
  phone: string
  countryCode: number
}

export interface EmailData {
  email: string
}

export interface DownloadFileData extends PhoneData {
  name: string
  investmentRange: string
  email: string
}

export interface DownloadFile2Data extends PhoneData {
  name: string
}

export interface UserData extends PhoneData, EmailData {
  name: string
}

export const createDeviceSellSchema = (count: number) =>
  z.discriminatedUnion('instant', [
    z.object({
      price: z.coerce
        .number({ invalid_type_error: 'validation.required' })
        .positive({ message: 'validation.required' }),
      count: z.coerce
        .number()
        .positive({ message: 'validation.required' })
        .max(count, { message: 'validation.max.hardwareCount' }),
      instant: z.literal(false)
    }),
    z.object({
      instant: z.literal(true),
      count: z.coerce
        .number()
        .positive({ message: 'validation.required' })
        .max(count, { message: 'validation.max.hardwareCount' })
    })
  ])

export const ReplenishSchema: ZodType<ReplenishData> = z.object({
  advance: z.coerce.number().positive({ message: 'validation.required' }),
  paymentMethod: z
    .number({ invalid_type_error: 'validation.required' })
    .or(z.string({ invalid_type_error: 'validation.required' }))
})

export const WithdrawSchema: ZodType<WithdrawData> = z
  .object({
    method: z
      .enum(
        [
          'balance',
          'btc',
          'usdt_trc20',
          'webmoney',
          'qiwi',
          'yoomoney',
          'card_rub',
          'card_uah',
          'card_kzt',
          'card_uzs',
          'card_gel',
          'card_eur',
          'card_usd',
          'phone_megafon',
          'phone_beeline',
          'phone_mts',
          'phone_tele2',
          'phone_beeline'
        ],
        { required_error: 'validation.required' }
      )
      .nullable(),
    amount: z.coerce
      .number({ invalid_type_error: 'validation.required' })
      .positive({ message: 'validation.required' }),
    account: z.string().nullable(),
    currency: z.enum(['BTC', 'USDT'], {
      invalid_type_error: 'validation.required'
    })
  })
  .refine(
    ({ account, method }) => method !== 'btc' || (account && validate(account)),
    {
      message: 'validation.address',
      path: ['account']
    }
  )
  .refine(
    ({ account, method }) =>
      method !== 'usdt_trc20' ||
      (account && /^T[A-Za-z1-9]{33}$/.test(account)),
    {
      message: 'validation.address',
      path: ['account']
    }
  )

export const SwapSchema: ZodType<SwapData> = z.object({
  amount: z.coerce
    .number({ invalid_type_error: 'validation.required' })
    .positive({ message: 'validation.required' }),
  from: z.enum(['BTC', 'USDT'], {
    invalid_type_error: 'validation.required'
  }),
  to: z.enum(['BTC', 'USDT'], {
    invalid_type_error: 'validation.required'
  })
})

export const SupportSchema: ZodType<SupportData> = z.object({
  category: z.coerce.string().refine((value) => value !== 'undefined', {
    message: 'validation.required.category'
  }),
  title: z.coerce.string().nonempty('validation.required.topic'),
  text: z.coerce.string().min(20, { message: 'validation.min.20' })
})

export const createDeviceOrderSchema = (
  hardwareCount: number,
  minAdvance: number
) => {
  const schema = z.object({
    orderAmount: z.coerce
      .number()
      .positive({ message: 'validation.required' })
      .max(hardwareCount, { message: 'validation.max.hardwareCount' }),
    advance: z.coerce
      .number()
      .positive({ message: 'validation.required' })
      .min(minAdvance, { message: 'validation.minAdvance' })
      .nullable(),
    paymentMethod: z
      .number({ invalid_type_error: 'validation.required' })
      .or(z.string({ invalid_type_error: 'validation.required' })),
    useBonusBalance: z.coerce.boolean()
  })

  return schema
}

export const CountrySelectSchema: ZodType<{ country: string }> = z.object({
  country: z.string({
    required_error: 'validation.required',
    invalid_type_error: 'validation.required'
  })
})

export const PartnerIDSchema: ZodType<PartnerID> = z.object({
  partnerId: z
    .string({
      required_error: 'validation.required',
      invalid_type_error: 'validation.required'
    })
    .min(1, { message: 'validation.required' })
})

export const GenerateLinkSchema: ZodType<GenerateLinkData> = z.object({
  utm_source: z.string().nullable(),
  utm_medium: z.string().nullable(),
  utm_campaign: z.string().nullable(),
  utm_content: z.string().nullable(),
  utm_term: z.string().nullable()
})

export const EmailDataSchema = z.object({
  email: z.coerce.string().email({ message: 'validation.invalid.email' })
})

const PhoneDataSchema = z.object({
  countryCode: z.coerce.number().positive({ message: 'validation.required' }),
  phone: z.coerce
    .string()
    .regex(/^[0-9]+$/, { message: 'validation.invalid.phone' })
})

export const DownloadFileSchema: ZodType<DownloadFileData> = z
  .object({
    name: z
      .string({ invalid_type_error: 'validation.required' })
      .nonempty({ message: 'validation.required' }),
    ...EmailDataSchema.shape,
    ...PhoneDataSchema.shape,
    investmentRange: z.string({ required_error: 'validation.required' })
  })
  .refine(({ countryCode, phone }) => isValidPhone({ countryCode, phone }), {
    message: 'validation.invalid.phone',
    path: ['phone']
  })

export const DownloadFile2Schema: ZodType<DownloadFile2Data> = z
  .object({
    name: z
      .string({ invalid_type_error: 'validation.required' })
      .nonempty({ message: 'validation.required' }),
    ...PhoneDataSchema.shape
  })
  .refine(({ countryCode, phone }) => isValidPhone({ countryCode, phone }), {
    message: 'validation.invalid.phone',
    path: ['phone']
  })

export const GetPromoCodeSchema: ZodType<UserData> = z
  .object({
    name: z
      .string({ invalid_type_error: 'validation.required' })
      .nonempty({ message: 'validation.required' }),
    ...EmailDataSchema.shape,
    ...PhoneDataSchema.shape
  })
  .refine(({ countryCode, phone }) => isValidPhone({ countryCode, phone }), {
    message: 'validation.invalid.phone',
    path: ['phone']
  })
