import Router from 'next/router'

import { CookiesProvider } from 'react-cookie'

import {
  DEV_HOSTNAMES,
  METRICS_API_BASE_URL,
  VERCEL_HOSTNAME_SUFFIX
} from '@/lib/constants'

const COUNTER_ID = 'PjbmoRgFBLX0q3fa8vzX'
const DEV_COUNTER_ID = 'm80xOLbYbTgerg4mUHMu'

const LANDING_A_COUNTER_ID = 'AkE68cxecs3QGUw55udb'
const LANDING_B_COUNTER_ID = 'unhMHGZgpdcjqehiBEHZ'

export function getCounterID() {
  if (Router.route.startsWith('/pages/a')) {
    return LANDING_A_COUNTER_ID
  }

  if (Router.route.startsWith('/pages/b')) {
    return LANDING_B_COUNTER_ID
  }

  return DEV_HOSTNAMES.includes(window.location.hostname) ||
    window.location.hostname?.endsWith(VERCEL_HOSTNAME_SUFFIX)
    ? DEV_COUNTER_ID
    : COUNTER_ID
}

export async function trackEvent(event: string = 'pageview') {
  const { cookies } = new CookiesProvider({})

  const formData = new FormData()

  for (const param of [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
    'referrer',
    'ref',
    'session_id'
  ]) {
    const cookie = cookies.get(param)

    if (cookie) {
      formData.append(param, cookies.get(param))
    }
  }

  formData.append('event', event)
  formData.append('path', Router.asPath)
  formData.append('language', Router.locale || 'en')
  formData.append('counter_id', getCounterID())

  const url = `${METRICS_API_BASE_URL}track`

  const options: RequestInit = {
    method: 'POST',
    body: formData,
    credentials: 'include'
  }

  try {
    const response = await fetch(url, options)
    const data = await response.json()
    return { sessionId: data.session_id }
  } catch (error: any) {
    console.log(error)
    return {}
  }
}
