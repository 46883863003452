import { Dispatch, SetStateAction, useEffect } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import { addEmail, addPhone, sendCode, updateAuthMethod } from '@/lib/api/users'
import { AuthData, SignInSchema } from '@/lib/schemes/auth'
import { PhoneData } from '@/lib/types'

import { ActionType, LoginOption } from '@/hooks/auth/useAccountLoginDetails'
import useAuthStore from '@/hooks/stores/useAuthStore'
import useCookies from '@/hooks/stores/useCookies'
import useLoading from '@/hooks/useLoading'

interface SignInFormProps {
  onClose?: () => void
  openConfirmEmailModal?: () => void
  openConfirmPhoneModal?: () => void
  setAuthData: Dispatch<SetStateAction<AuthData | undefined>>
  setIsEmailConfirmation?: Dispatch<SetStateAction<boolean>>
  setIsPhoneConfirmation?: Dispatch<SetStateAction<boolean>>
  action?: ActionType
  currentLoginData?: LoginOption | null
}

export const useSignInDialog = ({
  action,
  currentLoginData,
  onClose,
  openConfirmPhoneModal,
  openConfirmEmailModal,
  setAuthData
}: SignInFormProps) => {
  const { isLoading, tryLoad } = useLoading()
  const { countryCode } = useCookies()
  const { authToken } = useAuthStore()

  const form = useForm<AuthData>({
    resolver: zodResolver(SignInSchema),
    defaultValues: { mode: 'phone', countryCode: 7 }
  })

  const { reset, clearErrors, setValue } = form

  const handleCloseModal = () => {
    reset()
    clearErrors()
    onClose && onClose()
  }

  const onSubmit = async ({ phone, countryCode, mode, email }: AuthData) => {
    await tryLoad(async () => {
      if (mode === 'email' && email) {
        if (action === 'adding') {
          await addEmail({ email, authToken })
        } else if (action === 'editing') {
          await updateAuthMethod({
            authToken,
            newEmail: email,
            prevEmail: currentLoginData?.email as string,
            prevPhone: {
              phone: currentLoginData?.number,
              countryCode: currentLoginData?.countryCode
            } as PhoneData
          })
        } else {
          await sendCode({ email })
        }

        setAuthData({ email, mode: 'email' })
        openConfirmEmailModal && openConfirmEmailModal()
      } else if (phone && countryCode) {
        if (action === 'adding') {
          await addPhone({ phone, countryCode, authToken })
        } else if (action === 'editing') {
          await updateAuthMethod({
            authToken,
            newPhone: { phone, countryCode },
            prevEmail: currentLoginData?.email as string,
            prevPhone: {
              phone: currentLoginData?.number,
              countryCode: currentLoginData?.countryCode
            } as PhoneData
          })
        } else {
          await sendCode({ phone, countryCode })
        }

        setAuthData({ phone, countryCode, mode: 'phone' })
        openConfirmPhoneModal && openConfirmPhoneModal()
      }
      setTimeout(handleCloseModal, 0)
    })
  }

  useEffect(() => {
    if (countryCode) {
      setValue('countryCode', countryCode)
    }
  }, [countryCode, setValue])

  return {
    form,
    isLoading,
    onSubmit,
    handleCloseModal
  }
}
