import { create } from 'zustand'

interface SignInStore {
  isOpenSignInModal: boolean
  isUpdateEmail: boolean
  isUpdatePhone: boolean
  isOnboardingOpen: boolean
  isDemoOpen: boolean
  setIsUpdateEmail: (isUpdateEmail: boolean) => void
  setIsUpdatePhone: (isUpdatePhone: boolean) => void
  closeSignInModal: () => void
  openSignInModal: () => void
  openOnboardModal: () => void
  closeOnboardModal: () => void
  openDemoModal: () => void
  closeDemoModal: () => void
}

export const useSignInStore = create<SignInStore>((set) => ({
  isOpenSignInModal: false,
  isUpdateEmail: false,
  isUpdatePhone: false,
  isOnboardingOpen: false,
  isDemoOpen: false,
  setIsUpdateEmail: (isUpdateEmail) => set({ isUpdateEmail }),
  setIsUpdatePhone: (isUpdatePhone) => set({ isUpdatePhone }),
  closeSignInModal: () => set({ isOpenSignInModal: false }),
  openOnboardModal: () => set({ isOnboardingOpen: true }),
  closeOnboardModal: () => set({ isOnboardingOpen: false }),
  closeDemoModal: () => set({ isDemoOpen: false }),
  openDemoModal: () => set({ isDemoOpen: true }),
  openSignInModal: () => {
    set({ isUpdateEmail: false })
    set({ isUpdatePhone: false })
    set({ isOpenSignInModal: true })
  }
}))
