import Image from 'next/image'
import React from 'react'

import { FormattedMessage, useIntl } from 'react-intl'

import { useConfirmEmailForm } from '@/hooks/auth/forms/useConfirmEmailForm'
import { ActionType, LoginOption } from '@/hooks/auth/useAccountLoginDetails'

import Button from '@/components/Button'
import Input from '@/components/Input'
import { Dialog, SpecificDialogProps } from '@/components/dialogs/Dialog'
import Form from '@/components/form/Form'
import FormActions from '@/components/form/FormActions'
import FormField from '@/components/form/FormField'

interface ConfirmEmailDialogProps extends SpecificDialogProps {
  email?: string
  isUpdateNotificationEmail?: boolean
  openPrevModal?: () => void
  openSignInModal?: () => void
  needsEmailConfirmation?: boolean
  isAccountConfirm?: boolean
  action?: ActionType
  currentLoginData?: LoginOption | null
  authData?: LoginOption | null
}

export const ConfirmEmailDialog = ({
  email,
  isOpen,
  action,
  authData,
  isAccountConfirm,
  currentLoginData,
  isUpdateNotificationEmail,
  onClose,
  openPrevModal,
  openSignInModal,
  needsEmailConfirmation
}: ConfirmEmailDialogProps) => {
  const {
    form,
    countdown,
    isLoading,
    onSubmit,
    handleResend,
    handleCloseModal
  } = useConfirmEmailForm({
    email,
    action,
    authData,
    isAccountConfirm,
    enabled: isOpen,
    currentLoginData,
    isUpdateNotificationEmail,
    onClose,
    openSignInModal
  })
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = form

  const { formatMessage } = useIntl()

  return (
    <Dialog
      hasBackBtn={!!openPrevModal}
      hideCloseIcon={needsEmailConfirmation}
      onBackBtnClick={() => {
        if (openPrevModal) {
          openPrevModal()
          setTimeout(onClose, 0)
        }
      }}
      title={formatMessage({
        id: 'dialog.email.confirm.title',
        defaultMessage: 'Проверьте почту'
      })}
      {...{
        isOpen,
        onClose: needsEmailConfirmation ? () => null : handleCloseModal
      }}
    >
      <Form
        className="items-center pb-2 pt-5"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mb-4 flex h-40 justify-center">
          <Image
            className="h-full w-auto"
            alt="confirm"
            height={160}
            src="/images/dog-letter-wink.svg"
            width={106}
          />
        </div>
        <span className="mb-4 w-52 text-center text-x-base leading-x-base">
          <FormattedMessage
            {...{
              id: 'dialog.confirmEmail.text',
              defaultMessage: 'Введите код, отправленный на {email}',
              values: { email }
            }}
          />
        </span>
        <div className="w-full">
          <FormField className="mb-4 w-full" error={errors.code}>
            <Input
              className="text-center"
              type="text"
              autoComplete="one-time-code"
              inputMode="numeric"
              maxLength={6}
              pattern="\d{6}"
              placeholder={formatMessage({
                id: 'dialog.confirmEmail.code',
                defaultMessage: 'Код из письма'
              })}
              {...register('code')}
            />
          </FormField>
          <div className="mb-6 flex justify-center">
            {countdown ? (
              <div className="text-secondary-500">
                <FormattedMessage
                  defaultMessage="Повторная отправка через {countdown, plural, one {# секунду} few {# секунды} many {# секунд}}"
                  id="dialog.confirmPhone.resend"
                  values={{ countdown }}
                />
              </div>
            ) : (
              <div
                className="cursor-pointer text-primary-600"
                onClick={handleResend}
              >
                <FormattedMessage
                  {...{
                    id: 'button.resend',
                    defaultMessage: 'Отправить повторно'
                  }}
                />
              </div>
            )}
          </div>
          <FormActions className="grid-cols-1">
            <Button className="min-h-11 w-full" {...{ isLoading }}>
              <FormattedMessage
                {...{
                  id: 'button.confirm',
                  defaultMessage: 'Подтвердить'
                }}
              />
            </Button>
          </FormActions>
        </div>
      </Form>
    </Dialog>
  )
}
