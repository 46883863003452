import clsx from 'clsx'

import Icon from '@/components/Icon'

interface LoaderProps {
  className?: string
  size?: string
  color?: string
}

const Loader = ({
  className,
  size = 'h-6 w-6',
  color = 'text-secondary-500'
}: LoaderProps) => {
  return (
    <div className={clsx('flex items-center justify-center', className)}>
      <span className={clsx('block flex-none animate-spin', size)}>
        <Icon className={clsx(color, size)} name="spinner-24" />
      </span>
    </div>
  )
}

export default Loader
