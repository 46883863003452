import React, { PropsWithChildren } from 'react'

import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'

interface FormFieldProps {
  error?: any
  className?: string
  errorClassName?: string
  values?: Record<string, string | number>
}

const FormField = ({
  error,
  values,
  className,
  children,
  errorClassName
}: PropsWithChildren<FormFieldProps>) => {
  return (
    <div
      className={clsx(
        'group flex flex-col gap-1',
        error?.message && 'error',
        className
      )}
    >
      {children}
      {error?.message && (
        <span className={clsx('text-red-500', errorClassName)}>
          <FormattedMessage {...{ id: error.message, values }} />
        </span>
      )}
    </div>
  )
}

export default FormField
