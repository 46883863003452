import React from 'react'

import clsx from 'clsx'

import Icon from '@/components/Icon'

interface ResetFilterButtonProps {
  className?: string
  onReset?: () => void
}

const ResetFilterButton = ({ className, onReset }: ResetFilterButtonProps) => {
  return (
    <span
      onClick={(event) => {
        event.preventDefault()
        if (onReset) {
          onReset()
        }
      }}
    >
      <Icon
        className={clsx('mr-3 h-5 w-4 cursor-pointer text-zink-800', className)}
        name="close-16"
      />
    </span>
  )
}

export default ResetFilterButton
