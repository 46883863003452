import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { ReactElement, ReactNode } from 'react'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { YandexMetricaProvider } from 'next-yandex-metrica'
import { IntlProvider } from 'react-intl'

import { useMetrics } from '@/hooks/useMetrics'

import Layout from '@/components/Layout'
import MailRuTracker from '@/components/MailRuTracker'

import '@/assets/styles/globals.css'

import path from 'path'

const localeImport = require.context('@/locales', false, /\.json$/)

const messages: Record<string, any> = Object.fromEntries(
  localeImport.keys().map((key) => [path.parse(key).name, localeImport(key)])
)

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } }
})

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  useMetrics()

  const { locale: currentLocale } = useRouter()

  const locale = currentLocale || 'en'

  const getLayout = Component.getLayout ?? ((page) => <Layout>{page}</Layout>)

  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        <meta
          content="width=device-width, initial-scale=1, user-scalable=0"
          name="viewport"
        />
      </Head>
      <YandexMetricaProvider
        tagID={95858967}
        initParameters={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true
        }}
      >
        <IntlProvider locale={locale} messages={messages[locale]}>
          {getLayout(<Component {...pageProps} />)}
        </IntlProvider>
      </YandexMetricaProvider>
      <MailRuTracker id="3467185" />
    </QueryClientProvider>
  )
}
