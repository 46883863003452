import React, { Fragment, useState } from 'react'

import { Dialog as DialogHeadless, Transition } from '@headlessui/react'

import { useDialog } from '@/hooks/dialogs/useDialog'
import { useSignInStore } from '@/hooks/stores/useSignInStore'

import OnboardCountryStep from './OnboardCountryStep'
import OnboardEarnStep from './OnboardEarnStep'
import OnboardStep from './OnboardStep'
import OnboardVideoStep from './OnboardVideoStep'
import OnboardWelcomeStep from './OnboardWelcomeStep'
import { PromocodeSuccessDialog } from './PromocodeSuccessDialog'

enum OnboardingSteps {
  'WELCOME' = 'welcome',
  'VIDEO' = 'video',
  'EARN' = 'earn',
  'COUNTRY' = 'country'
}

const Onboarding = () => {
  const [currentStep, setCurrentStep] = useState<OnboardingSteps>(
    OnboardingSteps.COUNTRY
  )
  const { isOnboardingOpen, closeOnboardModal } = useSignInStore()

  const {
    isOpen: isOpenPromocodeModal,
    closeModal: closePromocodeModal,
    openModal: openPromocodeModal
  } = useDialog()

  const STEPS = [
    {
      value: OnboardingSteps.COUNTRY,
      step: (
        <OnboardCountryStep
          {...{ onClick: () => setCurrentStep(OnboardingSteps.WELCOME) }}
        />
      )
    },
    {
      value: OnboardingSteps.WELCOME,
      step: (
        <OnboardWelcomeStep
          {...{ onClick: () => setCurrentStep(OnboardingSteps.VIDEO) }}
        />
      )
    },
    {
      value: OnboardingSteps.VIDEO,
      step: (
        <OnboardVideoStep
          {...{ onClick: () => setCurrentStep(OnboardingSteps.EARN) }}
        />
      )
    },
    {
      value: OnboardingSteps.EARN,
      step: (
        <OnboardEarnStep
          {...{
            openPromocodeModal,
            onClick: () => {
              closeOnboardModal()
              setCurrentStep(OnboardingSteps.WELCOME)
            }
          }}
        />
      )
    }
  ]

  return (
    <>
      <DialogHeadless
        as="div"
        onClose={() => null}
        open={isOnboardingOpen}
        static
      >
        <Transition appear as={Fragment} show={isOnboardingOpen}>
          <Transition.Child
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-200 ease-in"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 z-50 flex tablet:bg-slate-700" />
          </Transition.Child>
        </Transition>
        {STEPS.map(({ value, step }) => (
          <OnboardStep
            key={value}
            {...{
              isOnboardingOpen,
              isStepOpen: currentStep === value,
              isVideoStep: value === OnboardingSteps.VIDEO
            }}
          >
            {step}
          </OnboardStep>
        ))}
      </DialogHeadless>

      <PromocodeSuccessDialog
        {...{ isOpen: isOpenPromocodeModal, onClose: closePromocodeModal }}
      />
    </>
  )
}

export default Onboarding
