import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { applyPromocode, checkPromocode, updateUserInfo } from '@/lib/api/users'
import { Promocode } from '@/lib/schemes'
import { formatCurrency } from '@/lib/utils'

import useLoading from '@/hooks/useLoading'

import Button from '@/components/Button'
import Icon from '@/components/Icon'
import Input from '@/components/Input'
import Loader from '@/components/Loader'
import Form from '@/components/form/Form'
import FormField from '@/components/form/FormField'

import { OnboardingChildProps } from './OnboardStep'

interface OnboardEarnStepProps extends OnboardingChildProps {
  openPromocodeModal: () => void
}

const OnboardEarnStep = ({
  onClick: closeOnboardModal,
  openPromocodeModal
}: OnboardEarnStepProps) => {
  const { locale } = useRouter()
  const queryClient = useQueryClient()
  const { isLoading, tryLoad } = useLoading()
  const { isLoading: isUpdateLoading, tryLoad: tryUpdateLoad } = useLoading()
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm<Promocode>({
    defaultValues: { hasPromocode: false, isPromocodeApplied: false }
  })

  const [bonusBalance, setBonusBalance] = useState('')

  const isPromocodeApplied = watch('isPromocodeApplied')
  const hasPromocode = watch('hasPromocode')
  const code = watch('code')

  const onSubmit = async ({ code }: Promocode) => {
    if (code) {
      await tryLoad(async () => {
        const { currency, amount } = await checkPromocode(code)
        currency &&
          amount &&
          setBonusBalance(formatCurrency(amount, currency, locale))
        setValue('isPromocodeApplied', true)
      })
    }
  }

  const handleClose = async () => {
    if (code && !isPromocodeApplied) {
      setError('code', { message: 'validation.promocode' })
    } else {
      clearErrors('code')
      await tryUpdateLoad(async () => {
        if (code) {
          await applyPromocode(code)
        }
        await updateUserInfo({ hasSeenOnboarding: true })
        await queryClient.invalidateQueries({
          queryKey: ['user-info']
        })
        if (code) {
          openPromocodeModal()
        }
        closeOnboardModal()
      })
    }
  }

  return (
    <>
      <Form
        className={clsx('items-center justify-center px-3 py-6 tablet:px-6')}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="h-[244px] flex-none">
          <Image
            className="h-full w-auto"
            alt="confirm"
            height={160}
            src="/images/dog-with-phone-on-sofa.svg"
            width={106}
          />
        </div>
        <h3 className="pb-6 pt-6 text-center text-xl font-medium leading-xl tablet:text-2xl tablet:leading-xs">
          <FormattedMessage
            {...{
              id: 'onboard.earn.title',
              defaultMessage: 'Начните зарабатывать {br} до 250% прямо сейчас',
              values: {
                br: <br className="hidden tablet:block" />
              }
            }}
          />
        </h3>
        <div className="w-full pb-7 tablet:max-w-[340px] tablet:pb-8">
          {hasPromocode ? (
            isPromocodeApplied ? (
              <div className="flex h-11 w-full items-center rounded-x-xl bg-primary-600/[0.05] px-3 py-2 text-x-base">
                <span>
                  {code} —{' '}
                  <FormattedMessage
                    {...{
                      id: 'onboard.earn.bonuses',
                      defaultMessage: 'Бонусы'
                    }}
                  />{' '}
                  {bonusBalance}
                </span>
                <span
                  className="ml-auto cursor-pointer text-secondary-500"
                  onClick={() => setValue('isPromocodeApplied', false)}
                >
                  <Icon className="h-4 w-4" name="close-16" />
                </span>
              </div>
            ) : (
              <FormField error={errors.code}>
                <div className="relative">
                  <Input className="pr-[104px]" {...register('code')} />
                  {isLoading ? (
                    <Loader
                      className="absolute right-3 top-1/2 -translate-y-1/2"
                      color="text-primary-600"
                    />
                  ) : (
                    <button
                      className={clsx(
                        'absolute right-0 top-1/2 -translate-y-1/2 cursor-pointer pr-3 text-x-base',
                        'font-medium text-primary-600 transition-colors hover:bg-transparent hover:!text-primary-500'
                      )}
                    >
                      <FormattedMessage
                        {...{ id: 'button.apply', defaultMessage: 'Применить' }}
                      />
                    </button>
                  )}
                </div>
              </FormField>
            )
          ) : (
            <Button
              className="border-none !p-0 text-x-md hover:bg-transparent hover:!text-primary-500"
              onClick={() => setValue('hasPromocode', true)}
              tag="div"
              variant="secondary"
            >
              <div className="flex items-center gap-2">
                <Icon className="h-6 w-6 flex-none" name="promo-24" />
                <span>
                  <FormattedMessage
                    {...{
                      id: 'onboard.promocode.have',
                      defaultMessage: 'У меня есть промокод'
                    }}
                  />
                </span>
              </div>
            </Button>
          )}
        </div>
        <Button
          className="min-h-11 w-full tablet:w-fit tablet:min-w-[180px]"
          isLoading={isUpdateLoading}
          onClick={handleClose}
          tag="div"
        >
          <FormattedMessage
            {...{ id: 'button.start', defaultMessage: 'Начать' }}
          />
        </Button>
      </Form>
    </>
  )
}

export default OnboardEarnStep
