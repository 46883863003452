import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { trackEvent } from '@/lib/api/metrics'

import useAuthStore from '@/hooks/stores/useAuthStore'

function useDemo() {
  const { isDemo, setIsDemo } = useAuthStore()
  const queryClient = useQueryClient()
  const router = useRouter()

  useEffect(() => {
    for (const queryKey of [
      'devices',
      'payments',
      'wallet-history',
      'cluster-stats',
      'sessions'
    ]) {
      queryClient.removeQueries({
        queryKey: [queryKey]
      })
    }
  }, [isDemo, queryClient])

  const onSetIsDemo = (isDemo: boolean) => {
    setIsDemo(isDemo)

    if (isDemo) {
      router.push('/dashboard')
      trackEvent('demo')
    }
  }

  return {
    isDemo,
    setIsDemo: onSetIsDemo
  }
}

export default useDemo
