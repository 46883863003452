import { useEffect, useState } from 'react'

const TIMEOUT = 30

export const useCountdown = ({ enabled }: { enabled: boolean }) => {
  const [countdown, setCountdown] = useState(TIMEOUT)
  const [startDate, setStartDate] = useState<number | null>()

  useEffect(() => {
    setStartDate(enabled ? Date.now() : null)
  }, [enabled])

  useEffect(() => {
    if (enabled) {
      const interval = setInterval(() => {
        if (startDate) {
          const currentTime = Date.now()
          const difference =
            TIMEOUT - Math.floor((currentTime - startDate) / 1000)
          setCountdown(difference)

          if (difference <= 0) {
            setCountdown(0)
            clearInterval(interval)
          }
        }
      }, 1000)

      return () => clearInterval(interval)
    } else {
      setCountdown(TIMEOUT)
    }
  }, [startDate, enabled])

  return { countdown, setStartDate }
}
