import React, { FC, PropsWithChildren } from 'react'

import clsx from 'clsx'

interface LabelProps {
  className?: string
  required?: boolean
}

const Label: FC<PropsWithChildren<LabelProps>> = ({
  children,
  className,
  required = false
}) => {
  return (
    <label
      className={clsx('text-x-base font-medium leading-x-base', className)}
    >
      {children}
      {required && <span className="text-rose-500">*</span>}
    </label>
  )
}

export default Label
