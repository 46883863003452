import { useRef, useState } from 'react'

export const useVideo = () => {
  const [isVideoVisible, setIsVideoVisible] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  const handleVideoEnd = () => {
    setIsVideoVisible(false)
    if (videoRef.current) {
      videoRef.current.pause()
    }
  }

  const handleVideoClick = () => {
    if (videoRef.current) {
      videoRef.current.play()
      setIsVideoVisible(true)
    }
  }

  return { videoRef, isVideoVisible, handleVideoEnd, handleVideoClick }
}
