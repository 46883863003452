function MailRuTag({ id }: { id: string }) {
  const scriptInjectorHTML = `
    var _tmr = window._tmr || (window._tmr = []);
    _tmr.push({id: "${id}", type: "pageView", start: (new Date()).getTime()});
    (function (d, w, id) {
      if (d.getElementById(id)) return;
      var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
      ts.src = "https://top-fwz1.mail.ru/js/code.js";
      var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
      if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
    })(document, window, "tmr-code");  
  `

  return (
    <script
      dangerouslySetInnerHTML={{
        __html: scriptInjectorHTML
      }}
    />
  )
}

function MailRuPixel({ id }: { id: string }) {
  const pixelSource = `https://top-fwz1.mail.ru/counter?id=${id};js=na`

  /* eslint-disable @next/next/no-img-element */
  return (
    <noscript>
      <div>
        <img
          alt=""
          src={pixelSource}
          style={{ position: 'absolute', left: '-9999px' }}
        />
      </div>
    </noscript>
  )
}

export default function MailRuTracker({ id }: { id: string }) {
  return (
    <>
      <MailRuTag id={id} />
      <MailRuPixel id={id} />
    </>
  )
}
