import React, { HTMLProps } from 'react'

import clsx from 'clsx'

const Rule = ({ className }: HTMLProps<HTMLHRElement>) => {
  return (
    <hr
      className={clsx(
        'block h-0 border border-t-0 border-slate-300',
        className
      )}
    />
  )
}

export default Rule
