export type SortOrder = 'asc' | 'desc'

export type DeviceType = 'mobile' | 'desktop'
export type DeviceStatus = 'online' | 'offline' | 'turning_on' | 'turning_off'
export type DeviceSortType = 'roi' | 'earnings' | 'efficiency' | 'price'
export type DeviceAvailability = null | true | false
export type SellDeviceType = 'part' | 'full'

export type CardIssuer = 'visa' | 'mastercard' | 'mir'

export type PaymentMethod = 'card' | 'card_ru' | 'balance' | 'usdt_trc20'
export type PaymentType = 'spending' | 'deposit'
export type SpendingType = 'electricity' | 'purchase' | 'repayment' | 'storage'
export type DepositType =
  | 'replenish'
  | 'promocode'
  | 'sale'
  | 'manual'
  | 'replenish_with_bonus'

export type WithdrawalMethod =
  | 'balance'
  | 'btc'
  | 'usdt_trc20'
  | 'webmoney'
  | 'qiwi'
  | 'yoomoney'
  | 'card_rub'
  | 'card_uah'
  | 'card_kzt'
  | 'card_uzs'
  | 'card_gel'
  | 'card_eur'
  | 'card_usd'
  | 'phone_megafon'
  | 'phone_beeline'
  | 'phone_mts'
  | 'phone_tele2'
  | 'phone_beeline'

export type WalletActionStatus = 'accepted' | 'pending' | 'success'
export type WalletActionType = 'withdraw' | 'swap' | 'income'

export type CryptoCurrency = 'BTC' | 'USDT'
export interface PromocodeData {
  bonuses: number
  amount: number
  currency: string
}
export interface Auth {
  accessToken: string
  refreshToken: string
}

export interface Currency {
  title: string
  code: string
  symbol: string
}

export interface Language {
  title: string
  code: string
}

export interface ConfirmAuthData
  extends Partial<PhoneData>,
    Partial<EmailData> {
  code: string
  metricsId?: string
}

export interface PhoneData {
  phone: string
  countryCode: number
}

export interface EmailData {
  email: string
}

interface PhoneDTO {
  country_code: number
  number: string
}

export interface Phone {
  countryCode: number
  number: string
}

export interface Earnings {
  value: number
  fiatEstimate: number
}

export interface EarningsDTO {
  value: number
  fiat_estimate: number
}

export interface BookedRanges {
  startDate: string
  endDate: string
}

export interface UserInfoDTO {
  emails: string[]
  phones: PhoneDTO[]
  balance: number
  currency: string
  earnings: {
    BTC: EarningsDTO
    USDT: EarningsDTO
  }
  paid_until: string | null
  support_id: string
  has_seen_onboarding: boolean
  bonus_balance: number
  country?: string
  notification_email: string
}

export interface UserInfo {
  emails: string[]
  phones: Phone[]
  balance: number
  currency: string
  earnings: {
    BTC: Earnings
    USDT: Earnings
  }
  paidUntil: string | null
  supportId: string
  hasSeenOnboarding: boolean
  bonusBalance: number
  country?: string
  notificationEmail: string
}

export interface SessionDTO {
  id: string
  type: DeviceType
  location: string
  created_at: number
  is_current: boolean
}

export interface Session {
  id: string
  type: DeviceType
  location: string
  createdAt: Date
  isCurrent: boolean
}

export interface LocationDataDTO {
  id: number
  name: string
  price_per_kwh: number
  country?: string
  currency?: string
}

export interface LocationData {
  id: number
  name: string
  pricePerKwh: number
  country?: string
  currency?: string
}

export interface PriceRange {
  from: number | null
  to: number | null
}

export interface ImageDTO {
  url?: string
  thumb_url: string
}

export interface Image {
  url?: string
  thumbURL: string
}

export interface AnnualForecastPerPriceDTO {
  mining_profit: number
  device_price: number
  total: number
}

export interface AnnualForecastPerPrice {
  miningProfit: number
  devicePrice: number
  total: number
}

export interface AnnualForecastDTO {
  current: AnnualForecastPerPriceDTO
  '75k': AnnualForecastPerPriceDTO
  '100k': AnnualForecastPerPriceDTO
}

export interface AnnualForecast {
  current: AnnualForecastPerPrice
  '75k': AnnualForecastPerPrice
  '100k': AnnualForecastPerPrice
}

export interface Estimate {
  earnings: number
  expenses: number
}

export interface MinerDTO {
  id: number
  hashrate: number
  power: number
  location: LocationDataDTO
  preorder_for?: Date | null
  name: string
  cover: ImageDTO
}

export interface Miner {
  id: number
  hashrate: number
  power: number
  location: LocationData
  preorderFor?: Date | null
  name: string
  cover: Image
}

export interface FundsFlow {
  earned: number
  spent: number
}

export interface FundsFlowOverTimeDTO {
  all_time: FundsFlow
  month: FundsFlow
  day: FundsFlow
}

export interface FundsFlowOverTime {
  allTime: FundsFlow
  month: FundsFlow
  day: FundsFlow
}

export interface InstallmentDTO {
  next_payment_at: string
  paid: number
  left: number
  terms: InstallmentTermsDTO
}

export interface Installment {
  nextPaymentAt: Date
  paid: number
  left: number
  terms: InstallmentTerms
}

export interface DeviceDTO {
  id: number
  has_installment: boolean
  status?: DeviceStatus
  is_on_sale: boolean
  hashrate: number
  miner: MinerDTO
  price: number
  monthly_estimate: Estimate
  currency: string
  aggregated?: FundsFlowOverTimeDTO
  daily?: Daily[]
  annual_forecast: AnnualForecastDTO
  daily_estimate: Estimate
  estimate_annual_profit: number
  power: number
  type: SellDeviceType
  count?: number
  price_per_th: number
  storage_price_per_day?: number
  storage_price_per_month?: number
  installment?: InstallmentDTO
  next_update_at: string
}

export interface Device {
  id: number
  hasInstallment: boolean
  status?: DeviceStatus
  isOnSale: boolean
  hashrate: number
  miner: Miner
  price: number
  monthlyEstimate: Estimate
  currency: string
  aggregated?: FundsFlowOverTime
  daily?: Daily[]
  annualForecast: AnnualForecast
  dailyEstimate: Estimate
  estimateAnnualProfit: number
  power: number
  type: SellDeviceType
  count?: number
  pricePerTH: number
  storagePricePerDay?: number
  storagePricePerMonth?: number
  installment?: Installment
  nextUpdateAt: string
}

export interface Daily {
  date: string
  hashrate: number
  power: number
  earnings: number
  spent: number
}

export interface CreditCardDTO {
  id: number
  last_digits: string
  issuer: CardIssuer
}

export interface CreditCard {
  id: number
  lastDigits: string
  issuer: CardIssuer
}

interface ClusterDTO {
  device_count: number
  hashrate: number
  power: number
}

interface Cluster {
  deviceCount: number
  hashrate: number
  power: number
}

export interface ClusterStatsDTO {
  currency: string
  cluster: ClusterDTO
  aggregated: FundsFlowOverTimeDTO
  daily: Daily[]
}

export interface ClusterStats {
  currency: string
  cluster: Cluster
  aggregated: FundsFlowOverTime
  daily: Daily[]
}

export interface WalletActionDTO {
  id: number
  amount: number
  status: WalletActionStatus
  type: WalletActionType
  method: WithdrawalMethod
  created_at: string
  account: string
  transaction_id: string
  to_be_withdrawn: number
  from: CryptoCurrency
  to: CryptoCurrency
  currency: string
  transactions?: Transaction[]
  fee: number
}

export interface WalletAction {
  id: number
  amount: number
  status: WalletActionStatus
  type: WalletActionType
  method: WithdrawalMethod
  createdAt: Date
  account: string
  transactionId: string
  toBeWithdrawn: number
  from: CryptoCurrency
  to: CryptoCurrency
  currency: string
  transactions?: Transaction[]
  fee: number
}

export interface PaymentDevice {
  id: number
  name: string
  count?: number
  hashrate?: number
}

export type Transaction = Pick<Payment, 'amount' | 'device'>

export interface PaymentDTO {
  id: number
  device?: PaymentDevice
  spending_type?: SpendingType
  deposit_type?: DepositType
  type: PaymentType
  created_at: string
  amount: number
  currency: string
  transactions?: Transaction[]
}

export interface Payment {
  id: number
  device?: PaymentDevice
  spendingType?: SpendingType
  depositType?: DepositType
  type: PaymentType
  createdAt: Date
  amount: number
  currency: string
  transactions?: Transaction[]
}

export interface OrderDTO {
  order_number: number
  payment_url: string
}

export interface Order {
  orderNumber: number
  paymentURL: string
}

export interface InstallmentTermsDTO {
  monthly_payment: number
  monthly_payment_percentage: number
  payment_count: number
  down_payment: number
  down_payment_percentage: number
}

export interface InstallmentTerms {
  monthlyPayment: number
  paymentCount: number
  monthlyPaymentPercentage: number
  downPayment: number
  downPaymentPercentage: number
}

export interface OrderEstimateDTO {
  daily_estimate: Estimate
  monthly_estimate: Estimate
  annual_forecast: AnnualForecastDTO
  prepaid_days: number
  total: number
  currency: string
  installment_terms?: InstallmentTermsDTO
  can_pay_with_balance: boolean
  discount_percentage: number
  max_hashrate?: number
  max_count?: number
  next_discount: {
    percentage: number
    count: number
  }
  hardware_price: number
  min_advance: number
  bonuses_spent: number
  bonus_discount: number
  can_pay_with_bonuses: boolean
  discount: number
  crypto_discount: number
  crypto_discount_percentage: number
  bonus_replenish: number
  bonus_replenish_percentage: number
}

export interface OrderEstimate {
  dailyEstimate: Estimate
  monthlyEstimate: Estimate
  annualForecast: AnnualForecast
  prepaidDays: number
  total: number
  currency: string
  installmentTerms?: InstallmentTerms
  canPayWithBalance: boolean
  discountPercentage: number
  maxHashrate?: number
  maxCount?: number
  nextDiscount: {
    percentage: number
    count: number
  }
  hardwarePrice: number
  minAdvance: number
  bonusesSpent: number
  bonusDiscount: number
  canPayWithBonuses: boolean
  discount: number
  cryptoDiscount: number
  cryptoDiscountPercentage: number
  bonusReplenish: number
  bonusReplenishPercentage: number
}

export interface MarketplaceDevicesDTO {
  devices: DeviceDTO[]
  max_price: number
  max_price_per_th: number
  min_price: number
  min_price_per_th: number
  total: number
}

export interface MarketplaceDevices {
  devices: Device[]
  maxPrice: number
  maxPricePerTH: number
  minPrice: number
  minPricePerTH: number
  total: number
}

export interface EstimateWithdrawal {
  fee: number
  fiatEstimate: number
  toBeWithdrawn: number
  from: string
  to: string
  currency: string
  forcedAmount?: number
}

export interface EstimateWithdrawalDTO {
  fee: number
  fiat_estimate: number
  to_be_withdrawn: number
  from: string
  to: string
  currency: string
  forced_amount?: number
}

export interface EstimateSwap {
  currency: string
  amount: number
}

export interface WithdrawalCategory {
  name: string
  title: string
  icon: string
  methods: WithdrawalMethod[]
}

export interface WithdrawalOption {
  title: string
  currency: string
  description: string
  min?: number
  max?: number
}

export interface ExchangeRate {
  value: number
  change: {
    '24h': number
  }
}

export interface ExchangeRateDTO {
  value: string
  change: {
    '24h': number
  }
}

export interface EnumsDTO {
  withdrawal_method: {
    [WITHDRAWAL_METHOD in WithdrawalMethod]: WithdrawalOption
  }
  withdrawal_category: WithdrawalCategory[]
}

export interface Enums {
  withdrawalMethod: {
    [WITHDRAWAL_METHOD in WithdrawalMethod]: WithdrawalOption
  }
  withdrawalCategory: WithdrawalCategory[]
}

export function imageFromDTO(imageDTO: ImageDTO): Image {
  return {
    url: imageDTO?.url,
    thumbURL: imageDTO?.thumb_url
  }
}

export function phoneFromDTO(phoneDTO: PhoneDTO): Phone {
  return {
    number: phoneDTO.number,
    countryCode: phoneDTO.country_code
  }
}

export function earningsFromDTO(earningsDTO: EarningsDTO): Earnings {
  return {
    value: earningsDTO.value,
    fiatEstimate: earningsDTO.fiat_estimate
  }
}

export function userInfoFromDTO(userDTO: UserInfoDTO): UserInfo {
  return {
    emails: userDTO.emails,
    balance: userDTO.balance,
    currency: userDTO.currency,
    earnings: {
      BTC: earningsFromDTO(userDTO.earnings.BTC),
      USDT: earningsFromDTO(userDTO.earnings.USDT)
    },
    paidUntil: userDTO.paid_until,
    supportId: userDTO.support_id,
    hasSeenOnboarding: userDTO.has_seen_onboarding,
    bonusBalance: userDTO.bonus_balance,
    phones: userDTO.phones?.map((phoneDTO) => phoneFromDTO(phoneDTO)),
    country: userDTO.country,
    notificationEmail: userDTO.notification_email
  }
}

export function creditCardsFromDTO(cardDTO: CreditCardDTO): CreditCard {
  return {
    id: cardDTO.id,
    lastDigits: cardDTO.last_digits,
    issuer: cardDTO.issuer
  }
}

export function minerFromDTO(minerDTO: MinerDTO): Miner {
  return {
    id: minerDTO.id,
    hashrate: minerDTO.hashrate,
    power: minerDTO.power,
    location: minerDTO.location && locationDataFromDTO(minerDTO.location),
    preorderFor: minerDTO.preorder_for
      ? new Date(minerDTO.preorder_for)
      : minerDTO.preorder_for,
    name: minerDTO.name,
    cover: imageFromDTO(minerDTO.cover)
  }
}

export function aggregatedFromDTO(
  fundsFlow: FundsFlowOverTimeDTO
): FundsFlowOverTime {
  return {
    allTime: fundsFlow.all_time,
    month: fundsFlow.month,
    day: fundsFlow.day
  }
}

export function deviceFromDTO(deviceDTO: DeviceDTO): Device {
  return {
    id: deviceDTO.id,
    hasInstallment: deviceDTO.has_installment,
    status: deviceDTO.status,
    isOnSale: deviceDTO.is_on_sale,
    hashrate: deviceDTO.hashrate,
    miner: minerFromDTO(deviceDTO.miner),
    price: deviceDTO.price,
    monthlyEstimate: deviceDTO.monthly_estimate,
    currency: deviceDTO.currency,
    aggregated: deviceDTO.aggregated && aggregatedFromDTO(deviceDTO.aggregated),
    daily: deviceDTO.daily,
    annualForecast:
      deviceDTO.annual_forecast &&
      annualForecastFromDTO(deviceDTO.annual_forecast),
    dailyEstimate: deviceDTO.daily_estimate,
    estimateAnnualProfit: deviceDTO.estimate_annual_profit,
    power: deviceDTO.power,
    type: deviceDTO.type,
    count: deviceDTO.count,
    pricePerTH: deviceDTO.price_per_th,
    storagePricePerDay: deviceDTO.storage_price_per_day,
    storagePricePerMonth: deviceDTO.storage_price_per_month,
    installment:
      deviceDTO.installment && installmentFromDTO(deviceDTO.installment),
    nextUpdateAt: deviceDTO.next_update_at
  }
}

export function locationDataFromDTO(
  locationDTO: LocationDataDTO
): LocationData {
  return {
    id: locationDTO.id,
    name: locationDTO.name,
    pricePerKwh: locationDTO.price_per_kwh,
    country: locationDTO.country,
    currency: locationDTO.currency
  }
}

function clusterFromDTO(clusterDTO: ClusterDTO): Cluster {
  return {
    deviceCount: clusterDTO.device_count,
    hashrate: clusterDTO.hashrate,
    power: clusterDTO.power
  }
}

export function clusterStatsFromDTO(
  statisticDTO: ClusterStatsDTO
): ClusterStats {
  return {
    currency: statisticDTO.currency,
    cluster: clusterFromDTO(statisticDTO.cluster),
    aggregated: aggregatedFromDTO(statisticDTO.aggregated),
    daily: statisticDTO.daily
  }
}

export function paymentFromDTO(paymentDTO: PaymentDTO): Payment {
  return {
    id: paymentDTO.id,
    device: paymentDTO.device,
    spendingType: paymentDTO.spending_type && paymentDTO.spending_type,
    depositType: paymentDTO.deposit_type && paymentDTO.deposit_type,
    type: paymentDTO.type,
    createdAt: new Date(paymentDTO.created_at),
    amount: paymentDTO.amount,
    currency: paymentDTO.currency,
    transactions: paymentDTO.transactions
  }
}

export function sessionFromDTO(sessionDTO: SessionDTO): Session {
  return {
    ...sessionDTO,
    isCurrent: sessionDTO.is_current,
    createdAt: new Date(sessionDTO.created_at)
  }
}

export function installmentFromDTO(
  installmentDTO: InstallmentDTO
): Installment {
  return {
    paid: installmentDTO.paid,
    left: installmentDTO.left,
    terms: installmentTermsFromDTO(installmentDTO.terms),
    nextPaymentAt: new Date(installmentDTO.next_payment_at)
  }
}

export function estimateFromDTO(estimateDTO: OrderEstimateDTO): OrderEstimate {
  return {
    dailyEstimate: estimateDTO.daily_estimate,
    monthlyEstimate: estimateDTO.monthly_estimate,
    annualForecast:
      estimateDTO.annual_forecast &&
      annualForecastFromDTO(estimateDTO.annual_forecast),
    prepaidDays: estimateDTO.prepaid_days,
    total: estimateDTO.total,
    currency: estimateDTO.currency,
    installmentTerms:
      estimateDTO.installment_terms &&
      installmentTermsFromDTO(estimateDTO.installment_terms),
    canPayWithBalance: estimateDTO.can_pay_with_balance,
    discountPercentage: estimateDTO.discount_percentage,
    maxHashrate: estimateDTO.max_hashrate && estimateDTO.max_hashrate,
    maxCount: estimateDTO.max_count && estimateDTO.max_count,
    nextDiscount: estimateDTO.next_discount,
    hardwarePrice: estimateDTO.hardware_price,
    minAdvance: estimateDTO.min_advance,
    bonusDiscount: estimateDTO.bonus_discount,
    bonusesSpent: estimateDTO.bonuses_spent,
    canPayWithBonuses: estimateDTO.can_pay_with_bonuses,
    discount: estimateDTO.discount && estimateDTO.discount,
    cryptoDiscount: estimateDTO.crypto_discount && estimateDTO.crypto_discount,
    cryptoDiscountPercentage:
      estimateDTO.crypto_discount_percentage &&
      estimateDTO.crypto_discount_percentage,
    bonusReplenish: estimateDTO.bonus_replenish,
    bonusReplenishPercentage: estimateDTO.bonus_replenish_percentage
  }
}

export function installmentTermsFromDTO(
  installmentTermsDTO: InstallmentTermsDTO
): InstallmentTerms {
  return {
    monthlyPayment: installmentTermsDTO.monthly_payment,
    paymentCount: installmentTermsDTO.payment_count,
    monthlyPaymentPercentage: installmentTermsDTO.monthly_payment_percentage,
    downPayment: installmentTermsDTO.down_payment,
    downPaymentPercentage: installmentTermsDTO.down_payment_percentage
  }
}

export function walletActionFromDTO(
  walletActionDTO: WalletActionDTO
): WalletAction {
  return {
    id: walletActionDTO.id,
    amount: walletActionDTO.amount,
    status: walletActionDTO.status,
    type: walletActionDTO.type,
    method: walletActionDTO.method,
    createdAt: new Date(walletActionDTO.created_at),
    account: walletActionDTO.account,
    transactionId: walletActionDTO.transaction_id,
    toBeWithdrawn: walletActionDTO.to_be_withdrawn,
    from: walletActionDTO.from,
    to: walletActionDTO.to,
    currency: walletActionDTO.currency,
    transactions: walletActionDTO.transactions,
    fee: walletActionDTO.fee
  }
}

export function marketplaceDevicesFromDTO(
  marketplaceDevicesDTO: MarketplaceDevicesDTO
): MarketplaceDevices {
  return {
    devices: marketplaceDevicesDTO.devices.map((device) =>
      deviceFromDTO(device)
    ),
    maxPrice: marketplaceDevicesDTO.max_price,
    maxPricePerTH: marketplaceDevicesDTO.max_price_per_th,
    minPrice: marketplaceDevicesDTO.min_price,
    minPricePerTH: marketplaceDevicesDTO.min_price_per_th,
    total: marketplaceDevicesDTO.total
  }
}

export function enumsFromDTO(enumsDTO: EnumsDTO): Enums {
  return {
    withdrawalMethod: enumsDTO.withdrawal_method,
    withdrawalCategory: enumsDTO.withdrawal_category
  }
}

export function estimateWithdrawalFromDTO(
  estimateWithdrawalDTO: EstimateWithdrawalDTO
): EstimateWithdrawal {
  return {
    fee: estimateWithdrawalDTO.fee,
    fiatEstimate: estimateWithdrawalDTO.fiat_estimate,
    toBeWithdrawn: estimateWithdrawalDTO.to_be_withdrawn,
    from: estimateWithdrawalDTO.from,
    to: estimateWithdrawalDTO.to,
    currency: estimateWithdrawalDTO.currency,
    forcedAmount: estimateWithdrawalDTO.forced_amount
  }
}

export function exchangeRateFromDTO(
  exchangeRateDTO: ExchangeRateDTO
): ExchangeRate {
  return {
    value: Number(exchangeRateDTO.value),
    change: {
      '24h': exchangeRateDTO.change['24h']
    }
  }
}

export function annualForecastPerPriceFromDTO(
  annualForecastPerPriceDTO: AnnualForecastPerPriceDTO
): AnnualForecastPerPrice {
  return {
    miningProfit: annualForecastPerPriceDTO.mining_profit,
    devicePrice: annualForecastPerPriceDTO.device_price,
    total: annualForecastPerPriceDTO.total
  }
}

export function annualForecastFromDTO(
  annualForecastDTO: AnnualForecastDTO
): AnnualForecast {
  return {
    '75k': annualForecastPerPriceFromDTO(annualForecastDTO['75k']),
    '100k': annualForecastPerPriceFromDTO(annualForecastDTO['100k']),
    current: annualForecastPerPriceFromDTO(annualForecastDTO.current)
  }
}
