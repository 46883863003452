import { PropsWithChildren } from 'react'

import clsx from 'clsx'

interface RowSkeletonProps {
  className?: string
}

const RowSkeleton = ({
  className,
  children
}: PropsWithChildren<RowSkeletonProps>) => {
  return (
    <div
      className={clsx(
        'animate-pulse rounded-md bg-slate-100 duration-200',
        className
      )}
    >
      {children}
    </div>
  )
}

export default RowSkeleton
