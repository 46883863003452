import Image from 'next/image'
import { useRouter } from 'next/router'
import React from 'react'

import clsx from 'clsx'
import { useIntl } from 'react-intl'

import { LANGUAGES } from '@/lib/constants'
import { Language } from '@/lib/types'

import Icon from '@/components/Icon'

import { Dialog, SpecificDialogProps } from './Dialog'

interface LanguageItemProps extends Language {
  active: boolean
  changeLanguage: (language: string) => void
}

const LanguageDialog = ({ isOpen, onClose }: SpecificDialogProps) => {
  const { formatMessage } = useIntl()
  const router = useRouter()

  const changeLanguage = (language: string) => {
    const { pathname, asPath, query } = router
    router.push({ pathname, query }, asPath, { locale: language })
    onClose()
  }

  return (
    <Dialog
      {...{
        isOpen,
        onClose,
        className: 'tablet:!max-w-[640px] w-fit',
        title: formatMessage({ id: 'language', defaultMessage: 'Язык' })
      }}
    >
      <div className="flex flex-col">
        <div className="flex flex-col gap-3">
          <div className="grid grid-cols-1 phone-md:grid-cols-2 phone-lg:grid-cols-3">
            {LANGUAGES.map((language) => (
              <LanguageItem
                key={language.code}
                {...{
                  ...language,
                  changeLanguage,
                  active: language.code === router.locale
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const LanguageItem = ({
  title,
  code,
  active,
  changeLanguage
}: LanguageItemProps) => {
  return (
    <div
      className={clsx(
        'flex cursor-pointer select-none items-center justify-between rounded-lg py-[10px] pl-3 pr-[10px] text-x-md',
        active && 'bg-slate-100 font-medium'
      )}
      onClick={() => changeLanguage(code)}
    >
      <div className="flex items-center gap-4 phone-lg:gap-5">
        <Image
          className="h-7 w-7 rounded-full border border-slate-200 phone-lg:h-9 phone-lg:w-9"
          alt={title}
          height={36}
          priority={true}
          src={`/images/flags/${code}.svg`}
          unoptimized
          width={36}
        />
        <span>{title}</span>
      </div>
      <div
        className={clsx(
          'relative h-6 w-6 flex-none rounded-full',
          active && 'border bg-primary-600'
        )}
      >
        <Icon
          className="absolute bottom-[5px] left-[5px] h-3 w-3 text-white"
          name="checkbox-20"
        />
      </div>
    </div>
  )
}

export default LanguageDialog
