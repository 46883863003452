import { useEffect } from 'react'

import { useQuery } from '@tanstack/react-query'

import { getOrderEstimate } from '@/lib/api/marketplace'
import { OrderEstimate, PaymentMethod } from '@/lib/types'

import { useFlashStore } from '@/hooks/stores/useFlashStore'

const useOrderEstimate = ({
  id,
  orderAmount,
  advance,
  currency,
  installment,
  isFullMiner,
  enabled = true,
  useBonusBalance,
  paymentMethod
}: {
  id?: number
  orderAmount?: number
  advance: number
  currency: string
  paymentMethod?: PaymentMethod | null
  installment?: boolean
  isFullMiner?: boolean
  enabled?: boolean
  useBonusBalance?: boolean
}) => {
  const { setErrorMessage } = useFlashStore()

  const { data, isLoading, error } = useQuery<OrderEstimate>({
    queryKey: [
      'order-estimate',
      id,
      orderAmount,
      advance,
      currency,
      installment,
      isFullMiner,
      useBonusBalance,
      paymentMethod
    ],
    queryFn: () =>
      getOrderEstimate({
        id,
        ...(isFullMiner
          ? { deviceCount: orderAmount }
          : { hashrate: orderAmount }),
        advance,
        currency,
        installment,
        useBonusBalance,
        paymentMethod
      }),
    enabled
  })

  useEffect(() => {
    setErrorMessage(error ? (error as any)?.message : '')
  }, [error, setErrorMessage])

  return { estimate: data, isLoading }
}

export default useOrderEstimate
