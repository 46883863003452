import { API_BASE_URL } from '@/lib/constants'
import { AuthData, AuthMethod, PhoneData } from '@/lib/schemes/auth'
import {
  Auth,
  ConfirmAuthData,
  CreditCard,
  CreditCardDTO,
  EmailData,
  PromocodeData,
  Session,
  SessionDTO,
  UserInfo,
  creditCardsFromDTO,
  sessionFromDTO,
  userInfoFromDTO
} from '@/lib/types'

import { APIError, fetchAPI } from '.'

export async function refreshTokens(refreshToken: string): Promise<Auth> {
  const response = await fetch(`${API_BASE_URL}users/refresh_token`, {
    method: 'POST',
    body: JSON.stringify({
      refresh_token: refreshToken
    })
  })

  const { access_token, refresh_token, error } = await response.json()

  if (response.status >= 400) {
    throw new APIError(response.status, error?.code, error?.message)
  }

  return {
    accessToken: access_token,
    refreshToken: refresh_token
  }
}

export async function sendCode({
  email,
  countryCode,
  phone
}: Partial<AuthData>): Promise<void> {
  const body = email ? { email } : { country_code: countryCode, phone }

  await fetchAPI('POST', 'users/send_code', {}, body)
}

export async function getCSRFToken(): Promise<{ csrfToken: string }> {
  const data = await fetchAPI('GET', 'users/csrf_token')

  return { csrfToken: data.csrf_token }
}

export async function signIn({
  countryCode,
  phone,
  code,
  email,
  metricsId
}: ConfirmAuthData): Promise<Auth> {
  const { access_token: accessToken, refresh_token: refreshToken } =
    await fetchAPI(
      'POST',
      'users/sign_in',
      {},
      {
        phone,
        country_code: countryCode,
        code,
        email,
        metrics_id: metricsId
      }
    )

  return {
    accessToken,
    refreshToken
  }
}

export async function authenticate({
  countryCode,
  phone,
  email
}: Partial<AuthData>): Promise<void> {
  const body = email ? { email } : { country_code: countryCode, phone }

  await fetchAPI('POST', 'users/authenticate', {}, body)
}

export async function confirmAuthentication({
  code
}: ConfirmAuthData): Promise<{ authToken: string }> {
  const { auth_token: authToken } = await fetchAPI(
    'POST',
    'users/confirm_authentication',
    {},
    { code }
  )

  return { authToken }
}

export async function signInByToken(authToken: string): Promise<Auth> {
  const { access_token: accessToken, refresh_token: refreshToken } =
    await fetchAPI('POST', 'users/sign_in', {}, { auth_token: authToken })

  return {
    accessToken,
    refreshToken
  }
}

export async function updateAuthMethod({
  authToken,
  newPhone,
  prevPhone,
  newEmail,
  prevEmail
}: {
  authToken: string
  prevPhone?: PhoneData
  newPhone?: PhoneData
  prevEmail?: string
  newEmail?: string
}): Promise<void> {
  const from = prevEmail
    ? { email: prevEmail }
    : {
        country_code: prevPhone?.countryCode,
        phone: prevPhone?.phone
      }
  const to = newEmail
    ? { email: newEmail }
    : {
        phone: newPhone?.phone,
        country_code: newPhone?.countryCode
      }

  await fetchAPI(
    'POST',
    'users/update_auth_method',
    {},
    { from, to, auth_token: authToken }
  )
}

export async function confirmAuthChange({
  code
}: {
  code: string
}): Promise<void> {
  await fetchAPI('POST', 'users/confirm_auth_change', {}, { code })
}

export async function confirmNewPhone({
  code
}: {
  code: string
}): Promise<void> {
  await fetchAPI('POST', 'users/confirm_new_phone', {}, { code })
}

export async function confirmNewEmail({
  code
}: {
  code: string
}): Promise<void> {
  await fetchAPI('POST', 'users/confirm_new_email', {}, { code })
}

export async function getUserInfo(): Promise<UserInfo> {
  const data = await fetchAPI('GET', 'users/info')

  return userInfoFromDTO(data)
}

export async function updateUserInfo({
  country,
  hasSeenOnboarding
}: Partial<UserInfo>): Promise<void> {
  await fetchAPI(
    'PATCH',
    'users/info',
    {},
    {
      ...(hasSeenOnboarding && { has_seen_onboarding: hasSeenOnboarding }),
      ...(country && { country })
    }
  )
}

export async function getSessions(): Promise<Session[]> {
  const sessions = (await fetchAPI('GET', 'users/sessions')) as SessionDTO[]

  return sessions.map((session) => sessionFromDTO(session))
}

export async function deleteSession(id: string): Promise<void> {
  await fetchAPI('DELETE', `users/sessions/${id}`)
}

export async function deleteCurrentSession(): Promise<void> {
  await fetchAPI('DELETE', 'users/sessions/current')
}

export async function getCreditCards(): Promise<CreditCard[]> {
  const cards = (await fetchAPI('GET', 'users/credit_cards')) as CreditCardDTO[]

  return cards.map((card) => creditCardsFromDTO(card))
}

export async function applyPromocode(code: string): Promise<void> {
  await fetchAPI('POST', 'users/apply_promocode', {}, { code })
}

export async function checkPromocode(code: string): Promise<PromocodeData> {
  const data = (await fetchAPI(
    'POST',
    'users/check_promocode',
    {},
    { code }
  )) as PromocodeData

  return data
}

export async function deletePhone({
  phone,
  countryCode,
  authToken
}: AuthMethod): Promise<void> {
  await fetchAPI(
    'POST',
    'users/delete_phone',
    {},
    { phone, country_code: countryCode, auth_token: authToken }
  )
}

export async function deleteEmail({
  email,
  authToken
}: AuthMethod): Promise<void> {
  await fetchAPI(
    'POST',
    'users/delete_email',
    {},
    { email, auth_token: authToken }
  )
}

export async function addPhone({
  phone,
  countryCode,
  authToken
}: AuthMethod): Promise<void> {
  await fetchAPI(
    'POST',
    'users/add_phone',
    {},
    { phone, country_code: countryCode, auth_token: authToken }
  )
}

export async function addEmail({
  email,
  authToken
}: AuthMethod): Promise<void> {
  await fetchAPI(
    'POST',
    'users/add_email',
    {},
    { email, auth_token: authToken }
  )
}

export async function updateNotificationEmail({
  email
}: EmailData): Promise<void> {
  await fetchAPI(
    'POST',
    'users/update_notification_email',
    {},
    { notification_email: email }
  )
}

export async function confirmNotificationEmailChange({
  code
}: {
  code: string
}): Promise<void> {
  await fetchAPI(
    'POST',
    'users/confirm_notification_email_change',
    {},
    { code }
  )
}
