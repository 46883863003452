import { useRouter } from 'next/router'

import { sortBy } from 'lodash'
import {
  Country,
  getCountries,
  getCountryCallingCode
} from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import en from 'react-phone-number-input/locale/en.json'
import ru from 'react-phone-number-input/locale/ru.json'

const CountryTitle = ({
  name,
  code,
  country
}: {
  name: string
  code?: string
  country: Country
}) => {
  return (
    <div className="flex items-center gap-x-2">
      <span className="h-4 w-6">{flags[country]?.({ title: name })}</span>
      <span className="text-sm">{name}</span>
      {code && <span className="text-primary-600">+{code}</span>}
    </div>
  )
}

function useCountrySelect(
  { hasCode }: { hasCode: boolean } = { hasCode: true }
) {
  const router = useRouter()
  const labels = router.locale === 'ru' ? ru : en

  const options = sortBy(getCountries(), (country) =>
    labels[country].toLocaleLowerCase()
  ).map((country) => {
    const code = hasCode && getCountryCallingCode(country)

    return {
      id: country,
      title: (
        <CountryTitle
          {...{ ...(code && { code }), country, name: labels[country] }}
        />
      ),
      value: country
    }
  })

  return { options }
}

export default useCountrySelect
