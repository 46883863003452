import Image from 'next/image'
import React from 'react'

import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'

import { useVideo } from '@/hooks/landing/useVideo'

import Button from '@/components/Button'
import Icon from '@/components/Icon'
import Form from '@/components/form/Form'

import { OnboardingChildProps } from './OnboardStep'

const OnboardVideoStep = ({ onClick }: OnboardingChildProps) => {
  const { videoRef, isVideoVisible, handleVideoEnd, handleVideoClick } =
    useVideo()

  return (
    <div className="flex h-full w-full flex-col items-center justify-center p-3 tablet:p-6">
      <div className="scroll-wrap max-h-[calc(90vh-48px)] w-full overflow-hidden overflow-y-auto">
        <Form className="items-center justify-center">
          <div
            className={clsx(
              'relative flex items-center overflow-hidden rounded-x-3xl border-[0.5px] border-slate-100',
              'h-[183px] w-full justify-center bg-sky-150 tablet:h-[386px] tablet:w-[685px]'
            )}
          >
            <video
              className={clsx(
                'h-full w-full cursor-pointer border-none outline-none',
                isVideoVisible ? 'block' : 'hidden'
              )}
              onClick={handleVideoEnd}
              onEnded={handleVideoEnd}
              ref={videoRef}
              src="/videos/asterbit.mp4"
            />
            {!isVideoVisible && (
              <div
                className={clsx(
                  'absolute z-10 flex h-full min-w-[320px] cursor-pointer select-none',
                  'justify-center gap-3 rounded-x-3xl tablet:items-center tablet:gap-6'
                )}
                onClick={handleVideoClick}
              >
                <div
                  className={clsx(
                    'absolute top-1/2 z-[1] flex translate-x-[0%] translate-y-[-50%] tablet:translate-x-[-28%] tablet:translate-y-[-45%]'
                  )}
                >
                  <div
                    className={clsx(
                      'flex h-[102px] w-[102px] items-center justify-center rounded-full bg-primary-600',
                      'transition-colors hover:bg-primary-500'
                    )}
                  >
                    <Icon
                      className="h-6 w-6 text-white tablet:h-5 tablet:w-5 desktop:h-6 desktop:w-6"
                      name="play-20"
                    />
                  </div>
                </div>
                <Image
                  className="relative bottom-[-10%] block w-[320px] tablet:hidden"
                  alt="poster"
                  height={138}
                  priority
                  src="/images/video-poster-bg-sm.svg"
                  width={241}
                />
                <Image
                  className="relative bottom-[-6%] hidden h-[400px] w-[685px] tablet:block"
                  alt="poster"
                  height={138}
                  priority
                  src="/images/video-poster-bg-short.svg"
                  width={241}
                />
              </div>
            )}
          </div>
          <h3 className="pb-4 pt-6 text-center text-xl font-medium leading-xl tablet:text-2xl tablet:leading-xs">
            <FormattedMessage
              {...{
                id: 'onboard.video.title',
                defaultMessage:
                  'Как получать пассивный доход {br} до 250% годовых?',
                values: {
                  br: <br />
                }
              }}
            />
          </h3>
          <p className="pb-4 text-center text-x-base leading-x-base tablet:pb-5">
            <FormattedMessage
              {...{
                id: 'onboard.video.text',
                defaultMessage: 'Узнайте за 1 м 22 секунды'
              }}
            />
          </p>
          <Button
            className="min-h-11 w-full tablet:w-fit tablet:min-w-[180px]"
            onClick={onClick}
            tag="div"
            variant="secondary"
          >
            <FormattedMessage
              {...{ id: 'button.next', defaultMessage: 'Далее' }}
            />
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default OnboardVideoStep
