import Link from 'next/link'
import React, { HTMLProps, MouseEvent, PropsWithChildren } from 'react'

import clsx from 'clsx'

import Icon from '@/components/Icon'

import { UrlObject } from 'url'

interface ButtonProps extends Omit<HTMLProps<HTMLButtonElement>, 'href'> {
  variant?: 'primary' | 'secondary'
  isLoading?: boolean
  href?: string | UrlObject
  tag?: keyof JSX.IntrinsicElements
  icon?: string
  iconClassName?: string
  onClick?: () => void
  prevent?: boolean
  target?: string
}

const Button = ({
  className,
  variant = 'primary',
  isLoading,
  href,
  tag,
  icon,
  iconClassName,
  disabled,
  onClick,
  prevent,
  children,
  target
}: PropsWithChildren<ButtonProps>): JSX.Element => {
  const Wrapper = href ? Link : tag || 'button'

  function handleClick(event: MouseEvent<Element>) {
    if (prevent) {
      event.preventDefault()
    }

    if (onClick && !disabled) {
      onClick()
    }
  }

  return (
    <Wrapper
      className={clsx(
        'relative flex cursor-pointer items-center justify-center rounded-xl border px-5 py-3',
        'text-x-base font-medium leading-none transition-colors',
        variant === 'primary'
          ? 'bg-primary-600 text-white hover:bg-primary-500'
          : 'border-slate-300 text-primary-600 hover:bg-slate-100',
        isLoading && 'pointer-events-none opacity-50',
        disabled && '!cursor-default opacity-50',
        className
      )}
      disabled={disabled}
      href={href as string & UrlObject}
      onClick={handleClick}
      target={target}
    >
      <span
        className={clsx(
          'flex select-none items-center',
          isLoading ? 'invisible' : ''
        )}
      >
        {children}
      </span>
      <span
        className={clsx(
          'absolute h-6 w-6 animate-spin',
          isLoading ? 'block' : 'hidden'
        )}
      >
        <span className={clsx('relative block h-6 w-6 animate-spin', variant)}>
          {[1, 2, 3].map((item) => (
            <span
              className={clsx(
                'border-box absolute inset-0 block rounded-full border-2 border-solid',
                variant === 'primary'
                  ? 'border-b-[rgba(255,255,255,0.02)] border-l-[rgba(255,255,255,0.12)] border-r-transparent border-t-white'
                  : 'border-b-[rgba(0,116,252,0.04)] border-l-[rgba(0,116,252,0.12)] border-r-transparent border-t-primary-600'
              )}
              key={item}
            ></span>
          ))}
        </span>
      </span>
      {icon && (
        <span className={clsx(children && 'mr-2', isLoading && 'hidden')}>
          <Icon className={clsx('h-4 w-4', iconClassName)} name={icon} />
        </span>
      )}
    </Wrapper>
  )
}

export default Button
