import { useCallback } from 'react'

import { useCookies as useCookiesLib } from 'react-cookie'
import { getCountryCallingCode } from 'react-phone-number-input'

const OPTIONS = {
  path: '/'
}

const useCookies = () => {
  const [cookies, setCookies] = useCookiesLib([
    'country',
    'referrer',
    'ref',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
    'session_id'
  ])

  const setCountry = (value: string) => {
    setCookies('country', value, OPTIONS)
  }

  const setReferrer = (value: string) => {
    setCookies('referrer', value, OPTIONS)
  }

  const setRef = (value: string) => {
    setCookies('ref', value, OPTIONS)
  }

  const setSessionId = useCallback(
    (value: string) => {
      setCookies('session_id', value, OPTIONS)
    },
    [setCookies]
  )

  const setUTMParams = ({
    utmCampaign,
    utmMedium,
    utmSource,
    utmContent,
    utmTerm
  }: {
    utmSource?: string
    utmMedium?: string
    utmCampaign?: string
    utmContent?: string
    utmTerm?: string
  }) => {
    utmCampaign && setCookies('utm_campaign', utmCampaign, OPTIONS)
    utmMedium && setCookies('utm_medium', utmMedium, OPTIONS)
    utmSource && setCookies('utm_source', utmSource, OPTIONS)
    utmContent && setCookies('utm_content', utmContent, OPTIONS)
    utmTerm && setCookies('utm_term', utmTerm, OPTIONS)
  }

  const countryCode = cookies.country
    ? Number(getCountryCallingCode(cookies.country?.toUpperCase()))
    : 7

  return {
    country: cookies.country,
    countryCode,
    ref: cookies.ref,
    referrer: cookies.referrer,
    utmCampaign: cookies.utm_campaign,
    utmMedium: cookies.utm_medium,
    utmSource: cookies.utm_source,
    sessionId: cookies.session_id,
    setCountry,
    setRef,
    setReferrer,
    setUTMParams,
    setSessionId
  }
}

export default useCookies
