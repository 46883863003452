import { Dispatch } from 'react'

import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { Auth } from '@/lib/types'
import { randomUUID } from '@/lib/utils'

interface AuthState extends Auth {
  uuid: string
  updatedAt: number
  authToken: string
  isDemo: boolean
  setAccessToken: Dispatch<string>
  setRefreshToken: Dispatch<string>
  setAuthToken: Dispatch<string>
  setIsDemo: Dispatch<boolean>
}

const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      uuid: randomUUID(),
      accessToken: '',
      refreshToken: '',
      authToken: '',
      isDemo: false,
      updatedAt: Date.now(),
      setAccessToken: (token: string) =>
        set({ accessToken: token, updatedAt: Date.now() }),
      setRefreshToken: (token: string) =>
        set({ refreshToken: token, updatedAt: Date.now() }),
      setAuthToken: (token: string) =>
        set({ authToken: token, updatedAt: Date.now() }),
      setIsDemo: (isDemo: boolean) => set({ isDemo })
    }),
    {
      name: 'authStore',
      storage: createJSONStorage(() => localStorage)
    }
  )
)

export default useAuthStore
