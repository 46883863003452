import Image from 'next/image'
import React from 'react'

import { useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import Button from '@/components/Button'
import { Dialog, SpecificDialogProps } from '@/components/dialogs/Dialog'
import Form from '@/components/form/Form'

export const DemoDialog = ({ isOpen, onClose }: SpecificDialogProps) => {
  const { handleSubmit } = useForm()

  return (
    <Dialog
      className="tablet:!max-w-[450px]"
      {...{ isOpen, onClose, hasTitle: false }}
    >
      <Form
        className="mt-6 items-center justify-center px-3 tablet:px-6"
        onSubmit={handleSubmit(onClose)}
      >
        <div className="mb-6 h-[224px] flex-none">
          <Image
            className="h-full w-auto"
            alt="Dog on search"
            height={160}
            src="/images/dog-search.svg"
            width={106}
          />
        </div>
        <h3 className="mb-4 text-center text-2xl font-medium">
          <FormattedMessage
            {...{
              id: 'dialog.demo.title',
              defaultMessage: 'Это демо-версия личного кабинет'
            }}
          />
        </h3>
        <p className="mb-8 text-center text-x-base leading-x-base">
          <FormattedMessage
            {...{
              id: 'dialog.demo.text',
              defaultMessage:
                'Совершить это действие вы сможете в кабинете с полным функционалом'
            }}
          />
        </p>
        <Button
          className="mb-3 w-full tablet:w-fit tablet:min-w-[180px]"
          variant="secondary"
        >
          <FormattedMessage
            {...{ id: 'button.close', defaultMessage: 'Закрыть' }}
          />
        </Button>
      </Form>
    </Dialog>
  )
}
