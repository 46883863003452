import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import clsx from 'clsx'
import { differenceInDays } from 'date-fns'
import { FormattedMessage } from 'react-intl'

import { formatCurrency } from '@/lib/utils'

import useRenderSide from '@/hooks/auth/useRenderSide'
import { useDialog } from '@/hooks/dialogs/useDialog'
import { useFlashStore } from '@/hooks/stores/useFlashStore'
import { useSignInStore } from '@/hooks/stores/useSignInStore'
import useDemo from '@/hooks/useDemo'
import { useFooterData } from '@/hooks/useFooterData'
import useUserInfo from '@/hooks/users/useUserInfo'

import BalanceInfoMessage from '@/components/BalanceInfoMessage'
import Button from '@/components/Button'
import DemoBar from '@/components/DemoBar'
import FlashMessage from '@/components/FlashMessage'
import Icon from '@/components/Icon'
import { DemoDialog } from '@/components/dialogs/DemoDialog'
import LanguageDialog from '@/components/dialogs/LanguageDialog'
import { MultiSignIn } from '@/components/dialogs/auth/MultiSignIn'
import Onboarding from '@/components/dialogs/onboarding/Onboarding'
import AccountMenu from '@/components/features/account/AccountMenu'
import RowSkeleton from '@/components/skeletons/RowSkeleton'

export default function Layout({ children }: { children: React.ReactNode }) {
  const { pathname, locale, query } = useRouter()
  const { errorMessage, successMessage, infoMessage } = useFlashStore()
  const { isBrowser, isSignedIn } = useRenderSide()
  const {
    balance,
    currency,
    earnings,
    isLoading,
    isFetching,
    paidUntil,
    hasSeenOnboarding
  } = useUserInfo()
  const { openOnboardModal } = useSignInStore()
  const { FOOTER_DATA } = useFooterData()
  const { isDemo } = useDemo()

  useEffect(() => {
    if (
      !isFetching &&
      isSignedIn &&
      hasSeenOnboarding === false &&
      !isDemo &&
      !pathname.startsWith('/docs') &&
      !pathname.startsWith('/support')
    ) {
      openOnboardModal()
    }
  }, [
    hasSeenOnboarding,
    isFetching,
    isSignedIn,
    isDemo,
    openOnboardModal,
    pathname
  ])

  const {
    isDemoOpen,
    isOpenSignInModal,
    closeDemoModal,
    openSignInModal,
    closeSignInModal
  } = useSignInStore()

  const {
    isOpen: isOpenLanguageModal,
    closeModal: closeLanguageModal,
    openModal: openLanguageModal
  } = useDialog()

  const isSignUp = query.sign_up

  useEffect(() => {
    if (isSignUp) openSignInModal()
  }, [isSignUp, openSignInModal])

  const differenceDays =
    paidUntil && differenceInDays(new Date(paidUntil), new Date())

  const isBalanceInsufficient =
    (differenceDays && differenceDays <= 7 && differenceDays >= 0) ||
    differenceDays === 0

  const isLandingPage = pathname === '/'

  return (
    <>
      <FlashMessage type="success" text={successMessage} />
      <FlashMessage type="error" text={errorMessage} />
      <FlashMessage type="info" text={infoMessage} />
      <Onboarding />
      <header
        className={clsx(
          'mx-auto flex min-h-[56px] w-full max-w-screen-container items-center',
          'justify-between px-3 py-2.5 tablet:min-h-[92px] tablet:px-4 desktop:px-5'
        )}
      >
        <Link
          className="flex h-5 flex-none select-none tablet:h-[27px]"
          href="/"
        >
          <Image
            className="h-full w-auto"
            alt="Asterbit Logo"
            height={20}
            priority
            src="/images/asterbit-logo.svg"
            width={102}
          />
        </Link>
        <div className="flex items-center gap-3 tablet:gap-6">
          <Button
            className="flex border-none !p-2"
            onClick={openLanguageModal}
            variant="secondary"
          >
            <Icon
              className="h-6 w-6 flex-none text-primary-600"
              name="globe-24"
            />
          </Button>

          {isBrowser && isSignedIn ? (
            <div className="flex items-center gap-8">
              {false && (
                <>
                  <div className="flex gap-1 text-x-base leading-x-base max-tablet:hidden">
                    <FormattedMessage
                      {...{ id: 'balance', defaultMessage: 'Баланс' }}
                    />{' '}
                    {isLoading ? (
                      <RowSkeleton className="h-4 w-12" />
                    ) : (
                      <span>
                        {formatCurrency(balance || 0, currency, locale)}
                      </span>
                    )}
                  </div>
                  <div className="flex gap-1 text-x-base leading-x-base max-tablet:hidden">
                    BTC{' '}
                    {isLoading ? (
                      <RowSkeleton className="h-4 w-12" />
                    ) : (
                      <span className="font-medium">
                        {earnings?.BTC?.value || 0}
                      </span>
                    )}
                  </div>
                </>
              )}

              <AccountMenu />
            </div>
          ) : (
            <div className="flex items-center gap-4">
              <Button onClick={openSignInModal} variant="secondary">
                <FormattedMessage
                  {...{
                    id: 'button.login',
                    defaultMessage: 'Войти'
                  }}
                />
              </Button>
            </div>
          )}
          <MultiSignIn
            {...{
              isOpen: isOpenSignInModal,
              onClose: closeSignInModal,
              onOpen: openSignInModal
            }}
          />
          <DemoDialog {...{ isOpen: isDemoOpen, onClose: closeDemoModal }} />
        </div>
      </header>
      <main
        className={clsx(
          'mx-auto flex w-full max-w-screen-container flex-auto flex-col px-3 tablet:px-4 desktop:px-5'
        )}
      >
        {isBrowser && <DemoBar />}
        {isBrowser && isSignedIn && isBalanceInsufficient && !isLandingPage && (
          <BalanceInfoMessage />
        )}

        {children}
      </main>
      <footer className="border-t-[0.5px] border-slate-300 pb-8 pt-4 tablet:py-6">
        <div className="mx-auto flex max-w-[1100px] gap-4 px-4 tablet:items-center desktop:px-5">
          <div className="flex flex-auto flex-col max-tablet:gap-6 tablet:flex-row tablet:items-center tablet:justify-between">
            <div className="flex items-center justify-between">
              <Link className="flex h-[18px] flex-none" href="/">
                <Image
                  className="h-full"
                  alt="Asterbit Logo"
                  height={24}
                  priority
                  src="/images/asterbit-logo-footer.svg"
                  width={92}
                />
              </Link>
              <Button
                className="mr-3 flex border-none !p-1.5 tablet:hidden"
                onClick={openLanguageModal}
                variant="secondary"
              >
                <Icon
                  className={clsx(
                    'h-6 w-6 flex-none text-secondary-100 transition-all hover:text-gray-800 hover:opacity-60'
                  )}
                  name="globe-24"
                />
              </Button>
            </div>

            <div className="flex flex-col gap-4 tablet:flex-row tablet:items-center tablet:gap-6">
              {FOOTER_DATA.filter(({ screens }) =>
                screens.includes('desktop')
              ).map(({ id, text, href, target }) => (
                <Link
                  className={clsx(
                    'text-x-base font-normal leading-x-base text-secondary-500 transition-all hover:text-shadow-xs'
                  )}
                  href={href}
                  key={id}
                  target={target ? '_blank' : ''}
                >
                  <FormattedMessage {...{ id, defaultMessage: text }} />
                </Link>
              ))}
              <Button
                className="hidden border-none !p-1.5 tablet:flex"
                onClick={openLanguageModal}
                variant="secondary"
              >
                <Icon
                  className={clsx(
                    'h-6 w-6 flex-none text-secondary-100 transition-all hover:text-gray-800 hover:opacity-60'
                  )}
                  name="globe-24"
                />
              </Button>
            </div>
          </div>
          <LanguageDialog
            {...{ isOpen: isOpenLanguageModal, onClose: closeLanguageModal }}
          />
        </div>
      </footer>
    </>
  )
}
