import { enUS, ru } from 'date-fns/locale'

import { Currency, Language } from '@/lib/types'

export const API_BASE_URL = 'https://api.asterbit.com/v1/'
export const API_DEV_BASE_URL = 'https://dev-api.asterbit.com/v1/'
export const DEV_HOSTNAMES = ['dev.asterbit.com', 'localhost']
export const VERCEL_HOSTNAME_SUFFIX = 'biterika-group.vercel.app'
export const METRICS_API_BASE_URL = 'https://metrics.click/'

export const DATE_TIME_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false
}

export const DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
  hour12: false
}

export const TIME_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: false
}

export const LOCALES: Record<string, Locale> = {
  ru,
  en: enUS
}

export const CURRENCIES: Currency[] = [
  {
    title: 'Российский рубль',
    code: 'RUB',
    symbol: '₽'
  },
  {
    title: 'Доллар США',
    code: 'USD',
    symbol: '$'
  },
  {
    title: 'Белорусский рубль',
    code: 'BYN',
    symbol: 'Br'
  }
]

export const LANGUAGES: Language[] = [
  {
    title: 'English',
    code: 'en'
  },
  {
    title: 'Русский',
    code: 'ru'
  }
]

export const DEFAULT_CURRENCY = 'RUB'

export const ACCEPTED_FILE_TYPES =
  'image/*, .doc, .docx, .pdf, ,rtf, .odt, .odf, .txt'

export const DEFAULT_HASHRATE = 1
export const DEFAULT_USD_ADVANCE = 5
export const DEFAULT_RUB_ADVANCE = 500

export const USD_STEP = 50 * 100
export const RUB_STEP = 5000 * 100
export const USD_STEP_PER_TH = 10 * 100
export const RUB_STEP_PER_TH = 500 * 100

export const LANDING_INVEST_AMOUNT_RUB = 3650
export const LANDING_BONUS_AMOUNT_RUB = 10_000
