import { Ref, forwardRef } from 'react'

import clsx from 'clsx'
import flags from 'react-phone-number-input/flags'
import { getCountryCallingCode } from 'react-phone-number-input/input'
import { Country } from 'react-phone-number-input/input'

import { getCountryFromCode } from '@/lib/utils'

import useCookies from '@/hooks/stores/useCookies'
import useCountrySelect from '@/hooks/useCountrySelect'

import SelectBox from '@/components/SelectBox'

interface CountrySelectProps {
  onChange?: (value: any) => void
  value?: number
  name?: string
  defaultValue?: any
  className?: string
  buttonClassName?: string
}

const CountrySelect = (
  {
    onChange,
    name,
    className,
    value: currentValue,
    buttonClassName
  }: CountrySelectProps,
  ref: Ref<HTMLDivElement>
) => {
  const { country } = useCookies()
  const { options } = useCountrySelect()

  const value: Country = currentValue
    ? getCountryFromCode(currentValue)
    : country?.toUpperCase() || 'RU'

  function onChangeHandler({ target: { value } }: { target: any }) {
    if (onChange) {
      onChange({
        target: { value: Number(getCountryCallingCode(value)), name }
      })
    }
  }

  return (
    <SelectBox
      className={clsx(className)}
      buttonClassName={clsx('!rounded-r-none', buttonClassName)}
      displayArrow={false}
      onChange={onChangeHandler}
      options={options}
      ref={ref}
      value={value}
    >
      {value && (
        <div className="flex items-center gap-2 pr-3">
          <span className="h-4 w-6 border-[0.5px] border-slate-300 !shadow-xl">
            {flags[value]?.({ title: '' })}
          </span>
          <span className="text-sm text-primary-600">
            +{getCountryCallingCode(value)}
          </span>
        </div>
      )}
    </SelectBox>
  )
}

export default forwardRef(CountrySelect)
