import { useState } from 'react'

export const useDialog = () => {
  const [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }
  function openModal() {
    setIsOpen(true)
  }

  return { isOpen, closeModal, openModal }
}
