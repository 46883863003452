import { HTMLProps, Ref, forwardRef } from 'react'

import clsx from 'clsx'

interface InputProps extends HTMLProps<HTMLInputElement> {
  suffix?: string | React.ReactNode
  suffixClassName?: string
}

const Input = (
  { className, suffix, suffixClassName, ...props }: InputProps,
  ref: Ref<HTMLInputElement>
) => {
  return (
    <div className="relative w-full">
      <input
        {...{
          ref,
          ...props,
          className: clsx(
            'placeholder:text-secondary w-full rounded-lg border border-slate-300 text-x-base',
            'px-3 py-2.5 transition-all duration-300 ease-linear  placeholder:transition-all focus:border-primary-600 focus:placeholder:opacity-0',
            'group-[.error]:!border-red-500',
            !!suffix && 'pr-10',
            className
          ),
          onWheelCapture: (event) => {
            // prevent scroll on number input
            ;(event.target as HTMLInputElement).blur()
          }
        }}
      />
      {suffix && (
        <span
          className={clsx(
            'absolute right-3 top-1/2 block w-max -translate-y-1/2 text-x-base text-secondary-500',
            suffixClassName
          )}
        >
          {suffix}
        </span>
      )}
    </div>
  )
}

export default forwardRef<HTMLInputElement, InputProps>(Input)
