import { useEffect } from 'react'

import { useQuery } from '@tanstack/react-query'

import { APIError } from '@/lib/api'
import { getUserInfo } from '@/lib/api/users'
import { DEFAULT_CURRENCY } from '@/lib/constants'
import { UserInfo } from '@/lib/types'

import useAuthStore from '@/hooks/stores/useAuthStore'

const useUserInfo = () => {
  const { accessToken, setAccessToken, isDemo } = useAuthStore()

  const { data, isLoading, error, isFetching } = useQuery<UserInfo>({
    queryKey: ['user-info', isDemo],
    queryFn: () => getUserInfo(),
    staleTime: 30_000, // 30 seconds
    refetchInterval: 30_000, // 30 seconds
    retryDelay: 1_000,
    refetchOnWindowFocus: true,
    enabled: !!accessToken || isDemo
  })

  useEffect(() => {
    if (error && (error as APIError).status == 401) {
      setAccessToken('')
    }
  }, [data, error, setAccessToken])

  return {
    ...data,
    isLoading,
    isFetching,
    currency: data?.currency || DEFAULT_CURRENCY
  }
}

export default useUserInfo
