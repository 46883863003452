import { useEffect, useState } from 'react'

import useAuthStore from '@/hooks/stores/useAuthStore'

const useRenderSide = () => {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  const isSignedIn = useAuthStore(
    ({ accessToken, isDemo }) => !!accessToken || isDemo
  )

  return { isSignedIn, isBrowser }
}

export default useRenderSide
