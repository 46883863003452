import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { updateUserInfo } from '@/lib/api/users'
import { CountrySelectSchema } from '@/lib/schemes'

import useCountrySelect from '@/hooks/useCountrySelect'
import useLoading from '@/hooks/useLoading'

import Button from '@/components/Button'
import SelectBox from '@/components/SelectBox'
import Form from '@/components/form/Form'
import FormField from '@/components/form/FormField'

import { OnboardingChildProps } from './OnboardStep'

const OnboardCountryStep = ({ onClick }: OnboardingChildProps) => {
  const { formatMessage } = useIntl()
  const { isLoading, tryLoad } = useLoading()
  const { options } = useCountrySelect({ hasCode: false })
  const queryClient = useQueryClient()

  const {
    watch,
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<{ country: string }>({
    resolver: zodResolver(CountrySelectSchema),
    defaultValues: {
      country: 'RU'
    }
  })

  const country = watch('country')
  const isRUAccount = country === 'RU'

  const onSubmit = async ({ country }: { country: string }) => {
    await tryLoad(async () => {
      await updateUserInfo({ country })
      await queryClient.invalidateQueries({
        queryKey: ['user-info']
      })
      await queryClient.invalidateQueries({
        queryKey: ['marketplace-devices']
      })
      onClick()
    })
  }

  return (
    <Form
      className="mx-auto flex max-w-[344px] flex-col items-center justify-center px-3 py-6 tablet:max-w-[368px] tablet:px-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="mb-6 h-[224px] flex-none">
        <Image
          className="h-full w-auto"
          alt="confirm"
          height={160}
          src="/images/dog-globe.svg"
          width={106}
        />
      </div>
      <h3 className="mb-6 text-center text-xl font-medium leading-xl tablet:text-2xl tablet:leading-xs">
        <FormattedMessage
          {...{
            id: 'onboard.country.title',
            defaultMessage: 'Выберите страну вашего аккаунта'
          }}
        />
      </h3>
      <FormField
        className={clsx('w-full', country ? 'mb-2.5' : 'mb-3')}
        error={errors.country}
      >
        <SelectBox
          {...{
            options,
            value: country,
            variant: 'form',
            placeholder: formatMessage({
              id: 'button.select',
              defaultMessage: 'Выберите'
            }),
            ...register('country')
          }}
        />
      </FormField>
      {country && (
        <span className="mb-3 self-start text-x-sm">
          <FormattedMessage
            {...{
              id: 'onboard.country.currency',
              defaultMessage: 'Валюта аккаунта — {currency} ({symbol})',
              values: {
                currency: formatMessage({
                  id: isRUAccount
                    ? 'onboard.country.rub'
                    : 'onboard.country.usd',
                  defaultMessage: isRUAccount ? 'рубль' : 'доллар США'
                }),
                symbol: isRUAccount ? 'RUB' : 'USD'
              }
            }}
          />
        </span>
      )}
      <p className="mb-5 rounded-xl bg-slate-100 px-3 py-2 text-x-sm leading-x-base">
        <FormattedMessage
          {...{
            id: 'onboard.country.text',
            defaultMessage:
              'Страна нужна для выбора основной валюты кабинета, способов оплаты и вывода средств'
          }}
        />
      </p>
      <Button
        className="mb-2.5 min-h-11 w-full tablet:w-fit tablet:min-w-[180px]"
        isLoading={isLoading}
      >
        <FormattedMessage
          {...{ id: 'button.start', defaultMessage: 'Начать' }}
        />
      </Button>
      <div className="text-center text-x-sm">
        <FormattedMessage
          defaultMessage="Продолжая, вы соглашаетесь с <terms>правилами пользования</terms> и <policy>политикой конфиденциальности</policy>"
          id="onboard.country.agreement"
          values={{
            terms: (chunks) => (
              <Link
                className="text-blue-600 transition-colors hover:text-primary-500"
                target="_blank"
                href={{
                  pathname: '/docs/[name]',
                  query: { name: 'terms_of_service' }
                }}
              >
                {chunks}
              </Link>
            ),
            policy: (chunks) => (
              <Link
                className="text-blue-600 transition-colors hover:text-primary-500"
                target="_blank"
                href={{
                  pathname: '/docs/[name]',
                  query: { name: 'privacy_policy' }
                }}
              >
                {chunks}
              </Link>
            )
          }}
        />
      </div>
    </Form>
  )
}

export default OnboardCountryStep
