import { Fragment } from 'react'

import clsx from 'clsx'

import { Transition } from '@headlessui/react'

type MessageType = 'error' | 'success' | 'info'

interface FlashMessageProps {
  type: MessageType
  text: string
}

const BACKGROUNDS: Record<MessageType, string> = {
  error: 'bg-red-400',
  info: 'bg-primary-600/80',
  success: 'bg-green-700'
}

const getBackground = (type: MessageType) => BACKGROUNDS[type]

const FlashMessage = ({ type, text }: FlashMessageProps) => {
  return (
    <Transition
      as={Fragment}
      enter="transition-opacity duration-100 ease-in"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-100 ease-out"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={!!text}
    >
      <div
        className={clsx(
          'pointer-events-none fixed left-1/2 top-7 z-[60] w-11/12 -translate-x-1/2',
          'rounded-xl p-3 backdrop-blur backdrop-filter tablet:w-4/12 tablet:min-w-[343px]',
          getBackground(type)
        )}
      >
        <p className="text-center text-base text-white">{text}</p>
      </div>
    </Transition>
  )
}

export default FlashMessage
