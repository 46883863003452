import { useMemo } from 'react'

import { CURRENCIES } from '@/lib/constants'

import useRenderSide from './auth/useRenderSide'
import useUserInfo from './users/useUserInfo'

export const useCurrencySymbol = () => {
  const { isBrowser } = useRenderSide()
  const { currency } = useUserInfo()

  const currencySymbol = useMemo(
    () => CURRENCIES.find(({ code }) => code === currency)?.symbol,
    [currency]
  ) as string

  return { symbol: isBrowser ? currencySymbol : '' }
}
