import React, { useState } from 'react'

import { AuthData } from '@/lib/schemes/auth'

import { ActionType, LoginOption } from '@/hooks/auth/useAccountLoginDetails'
import { useDialog } from '@/hooks/dialogs/useDialog'

import { SpecificDialogProps } from '@/components/dialogs/Dialog'
import { ConfirmEmailDialog } from '@/components/dialogs/auth/ConfirmEmailDialog'
import ConfirmPhoneDialog from '@/components/dialogs/auth/ConfirmPhoneDialog'
import { SignInDialog } from '@/components/dialogs/auth/SignInDialog'

interface MultiSignInProps extends SpecificDialogProps {
  onOpen: () => void
  action?: ActionType
  currentLoginData?: LoginOption | null
}

export const MultiSignIn = ({
  isOpen,
  action,
  currentLoginData,
  onOpen,
  onClose
}: MultiSignInProps) => {
  const [authData, setAuthData] = useState<AuthData>()

  const {
    isOpen: isConfirmEmailOpen,
    openModal: openConfirmEmailModal,
    closeModal: closeConfirmEmailModal
  } = useDialog()

  const {
    isOpen: isConfirmPhoneOpen,
    openModal: openConfirmPhoneModal,
    closeModal: closeConfirmPhoneModal
  } = useDialog()

  return (
    <>
      <SignInDialog
        {...{
          action,
          isOpen,
          onClose,
          setAuthData,
          currentLoginData,
          openConfirmEmailModal,
          openConfirmPhoneModal
        }}
      />
      <ConfirmEmailDialog
        {...{
          action,
          currentLoginData,
          openPrevModal: onOpen,
          isOpen: isConfirmEmailOpen,
          onClose: closeConfirmEmailModal,
          email: authData?.email
        }}
      />
      <ConfirmPhoneDialog
        {...{
          action,
          currentLoginData,
          isOpen: isConfirmPhoneOpen,
          onClose: closeConfirmPhoneModal,
          openPrevModal: onOpen,
          phoneData: {
            phone: authData?.phone as string,
            countryCode: authData?.countryCode as number
          }
        }}
      />
    </>
  )
}
