import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { trackEvent } from '@/lib/api/metrics'

import useCookies from '@/hooks/stores/useCookies'

export const useMetrics = () => {
  const router = useRouter()
  const { ref, referrer, setReferrer, setRef, setUTMParams, setSessionId } =
    useCookies()

  useEffect(() => {
    if (router.isReady) {
      if (document.referrer && document.referrer != referrer) {
        setReferrer(document.referrer)
      }

      const {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term,
        a,
        p
      } = router.query

      setUTMParams({
        utmSource: utm_source as string,
        utmMedium: utm_medium as string,
        utmCampaign: utm_campaign as string,
        utmContent: utm_content as string,
        utmTerm: utm_term as string
      })

      if (!ref && (a || p)) {
        setRef((a || p) as string)
      }
    }
  }, [
    router.isReady,
    router.query,
    ref,
    referrer,
    setReferrer,
    setRef,
    setUTMParams
  ])

  useEffect(() => {
    if (router.isReady) {
      const handleTrackEvent = async () => {
        try {
          const { sessionId } = await trackEvent()
          if (sessionId) {
            setSessionId(sessionId)
          }
        } catch (error) {
          console.error(error)
        }
      }

      handleTrackEvent()
    }
  }, [router.asPath, router.isReady, setSessionId])
}
