import { useRouter } from 'next/router'

import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { setJivoContactInfo, setJivoCustomData } from '@/lib/api/jivo'
import {
  addPhone,
  authenticate,
  confirmAuthChange,
  confirmAuthentication,
  confirmNewPhone,
  deleteEmail,
  deletePhone,
  getUserInfo,
  sendCode,
  signIn,
  updateAuthMethod
} from '@/lib/api/users'
import { ConfirmAuthData, ConfirmAuthSchema } from '@/lib/schemes/auth'
import { PhoneData, UserInfo } from '@/lib/types'
import { formatPhone } from '@/lib/utils'

import { ActionType, LoginOption } from '@/hooks/auth/useAccountLoginDetails'
import useAuthStore from '@/hooks/stores/useAuthStore'
import useCookies from '@/hooks/stores/useCookies'
import { useFlashStore } from '@/hooks/stores/useFlashStore'
import { useSignInStore } from '@/hooks/stores/useSignInStore'
import { useCountdown } from '@/hooks/useCountDown'
import useLoading from '@/hooks/useLoading'

interface ConfirmPhoneFormProps {
  enabled: boolean
  phoneData?: PhoneData
  openPrevModal?: () => void
  onClose?: () => void
  action?: ActionType
  openSignInModal?: () => void
  isAccountConfirm?: boolean
  currentLoginData?: LoginOption | null
  authData?: LoginOption | null
}

export const useConfirmPhoneForm = ({
  action,
  enabled,
  authData,
  phoneData,
  onClose,
  openPrevModal,
  openSignInModal,
  isAccountConfirm,
  currentLoginData
}: ConfirmPhoneFormProps) => {
  const router = useRouter()
  const { formatMessage } = useIntl()
  const { sessionId } = useCookies()
  const queryClient = useQueryClient()
  const { isLoading, tryLoad } = useLoading()
  const { tryLoad: tryResend } = useLoading()
  const { setSuccessMessage } = useFlashStore()
  const { openOnboardModal } = useSignInStore()
  const { setStartDate, countdown } = useCountdown({ enabled })
  const { setAccessToken, setRefreshToken, setAuthToken, authToken } =
    useAuthStore()

  const { phone, countryCode } = phoneData || {}

  const form = useForm<ConfirmAuthData>({
    resolver: zodResolver(ConfirmAuthSchema)
  })

  const { reset, setValue, clearErrors } = form

  const onBackBtnClick = () => {
    openPrevModal && openPrevModal()
    onClose && setTimeout(onClose, 0)
  }

  const handleCloseModal = () => {
    reset()
    clearErrors()
    onClose && onClose()
  }

  const accountConfirmation = async ({ code }: { code: string }) => {
    const { accessToken, refreshToken } = await signIn({
      countryCode,
      phone,
      code,
      metricsId: sessionId
    })

    setAccessToken(accessToken)
    setRefreshToken(refreshToken)

    if (router.route === '/') {
      await router.push('/dashboard?from=auth')
    } else {
      if (!router.route.includes('/shop')) {
        await router.push('/dashboard?from=auth')
      }

      const info = await queryClient.fetchQuery<Promise<UserInfo>>(
        ['user-info'],
        () => getUserInfo()
      )

      await queryClient.invalidateQueries({
        queryKey: ['marketplace-devices']
      })

      if (!info.hasSeenOnboarding) {
        openOnboardModal()
      }

      setJivoContactInfo({
        email: info.emails[0],
        phone: formatPhone(info.phones[0]?.countryCode, info.phones[0]?.number)
      })

      setJivoCustomData([
        {
          title: 'User ID',
          content: info.supportId
        }
      ])
    }
  }

  const onSubmit = async ({ code }: ConfirmAuthData) => {
    await tryLoad(async () => {
      if (isAccountConfirm) {
        const { authToken } = await confirmAuthentication({ code })

        setAuthToken(authToken)

        if (action === 'deletion' && currentLoginData) {
          if (currentLoginData.email) {
            await deleteEmail({
              email: currentLoginData.email,
              authToken
            })
          } else {
            await deletePhone({
              countryCode: currentLoginData?.countryCode,
              phone: currentLoginData?.number,
              authToken
            })
          }
          await queryClient.invalidateQueries({
            queryKey: ['user-info']
          })

          setSuccessMessage(
            formatMessage({
              id: 'success.message.phone.delete',
              defaultMessage: 'Способ входа удален успешно'
            })
          )
        }
      } else if (action === 'adding') {
        await confirmNewPhone({ code })
        await queryClient.invalidateQueries({
          queryKey: ['user-info']
        })
        setSuccessMessage(
          formatMessage({
            id: 'success.message.phone.add',
            defaultMessage: 'Способ входа добавлен успешно'
          })
        )
      } else if (action === 'editing') {
        await confirmAuthChange({ code })
        await queryClient.invalidateQueries({
          queryKey: ['user-info']
        })

        setSuccessMessage(
          formatMessage({
            id: 'success.message.phone.change',
            defaultMessage: 'Способ входа изменен успешно'
          })
        )
      } else {
        await accountConfirmation({ code })
      }

      if (openSignInModal && action !== 'deletion') {
        openSignInModal()
      }

      handleCloseModal()
    })
  }

  const handleResend = async () => {
    if (phone && countryCode) {
      setValue('code', '')
      await tryResend(async () => {
        setStartDate(Date.now())
        if (isAccountConfirm) {
          await authenticate({
            phone: authData?.number,
            countryCode: authData?.countryCode,
            email: authData?.email
          })
        } else if (action === 'adding') {
          await addPhone({ phone, countryCode, authToken })
        } else if (action === 'editing') {
          await updateAuthMethod({
            authToken,
            newPhone: { phone, countryCode },
            prevEmail: currentLoginData?.email as string,
            prevPhone: {
              phone: currentLoginData?.number,
              countryCode: currentLoginData?.countryCode
            } as PhoneData
          })
        } else {
          await sendCode({ phone, countryCode })
        }
      })
    }
  }

  const phoneTitle = isAccountConfirm
    ? formatMessage({
        id: 'dialog.account.confirm.title',
        defaultMessage: 'Подтверждение аккаунта'
      })
    : countryCode && phone && formatPhone(countryCode, phone)

  return {
    form,
    countdown,
    isLoading,
    phoneTitle,
    onSubmit,
    handleResend,
    onBackBtnClick,
    handleCloseModal
  }
}
