import React, { PropsWithChildren, Ref, forwardRef } from 'react'

import clsx from 'clsx'

import { Combobox, Listbox, Popover } from '@headlessui/react'

import Icon from '@/components/Icon'
import Loader from '@/components/Loader'
import { SelectOption } from '@/components/SelectBox'
import ResetFilterButton from '@/components/features/shop/ResetFilterButton'
import SelectArrow from '@/components/selectbox/SelectArrow'

interface SelectBoxButtonProps {
  selectedOption?: SelectOption
  icon?: string
  displayArrow?: boolean
  as: typeof Listbox.Button | typeof Combobox.Button | typeof Popover.Button
  className?: string
  isLoading?: boolean
  hasResetButton?: boolean
  onReset?: () => void
  variant: 'default' | 'form'
}

const SelectBoxButton = (
  {
    icon,
    children,
    hasResetButton,
    displayArrow,
    selectedOption,
    as: Component,
    className,
    isLoading,
    variant,
    onReset
  }: PropsWithChildren<SelectBoxButtonProps>,
  ref: Ref<HTMLButtonElement>
) => {
  return (
    <Component
      className={({ open }: { open: boolean }) =>
        clsx(
          'relative flex h-11 w-full cursor-pointer items-center justify-between',
          'gap-2 whitespace-nowrap py-2.5 pl-3',
          'transition-all duration-150 ease-linear',
          'focus:border-primary-600 group-[.error]:!border-red-500',
          variant === 'form'
            ? 'rounded-lg border border-solid border-slate-300 bg-white'
            : 'rounded-xl bg-slate-100',
          open && '!border-primary-600 bg-slate-200',
          className
        )
      }
      ref={ref}
    >
      {({ open }: { open: boolean }) => (
        <>
          <div
            className={clsx(
              'flex items-center gap-3 overflow-hidden',
              'whitespace-nowrap text-x-base leading-5',
              selectedOption?.title ? 'color-sub' : 'text-secondary-500',
              hasResetButton && 'font-medium'
            )}
          >
            {icon && <Icon className="h-2.5 w-8" name={icon} />}
            {children}
          </div>
          {isLoading && <Loader className="ml-auto" size="w-4 h-4" />}
          {hasResetButton ? (
            <ResetFilterButton {...{ onReset }} />
          ) : (
            displayArrow && <SelectArrow className="mr-3" open={open} />
          )}
        </>
      )}
    </Component>
  )
}

export default forwardRef(SelectBoxButton)
