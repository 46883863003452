import React, { FormEvent, PropsWithChildren } from 'react'

import clsx from 'clsx'

interface FormProps {
  className?: string
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void
}

const Form = ({
  children,
  className,
  onSubmit
}: PropsWithChildren<FormProps>) => {
  return (
    <form className={clsx('flex h-full flex-col', className)} {...{ onSubmit }}>
      {children}
    </form>
  )
}

export default Form
