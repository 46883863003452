import React from 'react'

import { clsx } from 'clsx'
import { FormattedMessage } from 'react-intl'

import useDemo from '@/hooks/useDemo'

import Icon from '@/components/Icon'

const DemoBar = ({ className }: { className?: string }) => {
  const { isDemo } = useDemo()

  if (!isDemo) {
    return null
  }

  return (
    <div
      className={clsx(
        'flex max-w-screen-container items-center justify-between rounded-xl',
        'mx-auto mb-4 w-full px-3 py-2.5 text-sm text-x-base max-phone-lg:gap-2.5',
        'bg-amber-250 max-phone-lg:flex-col max-phone-lg:items-start',
        !isDemo && 'phone-lg:hidden',
        className
      )}
    >
      <div className="flex items-center gap-5 phone-lg:gap-3">
        <Icon
          className="inline h-4 w-4 flex-none cursor-pointer text-amber-500"
          name="grid-plus-20"
        />

        <p>
          <FormattedMessage
            {...{
              id: 'demo.text',
              defaultMessage: 'Это демо-версия личного кабинета'
            }}
          />
        </p>
      </div>
    </div>
  )
}

export default DemoBar
