import clsx from 'clsx'

interface IconProps {
  name: string
  stroke?: string
  className?: string
}

const Icon = ({ className, name, stroke }: IconProps) => {
  return (
    <svg className={clsx(className)} stroke={stroke}>
      {/* TODO: remove hardcoded path */}
      <use xlinkHref={`/_next/static/sprite.svg#${name}`} />
    </svg>
  )
}

export default Icon
