import React, { Fragment, PropsWithChildren } from 'react'

import clsx from 'clsx'

import { Dialog as DialogHeadless, Transition } from '@headlessui/react'

import Icon from '@/components/Icon'

import Button from './Button'

export interface SpecificDrawersProps {
  isOpen: boolean
  onClose: () => void
}
interface DrawerProps extends SpecificDrawersProps {
  title?: string | JSX.Element
  className?: string
  buttonLabel?: string
  withoutPadding?: boolean
  wrapperClassName?: string
}

export const Drawer = ({
  title,
  isOpen,
  children,
  className,
  buttonLabel,
  wrapperClassName,
  withoutPadding = false,
  onClose
}: PropsWithChildren<DrawerProps>) => {
  return (
    <Transition appear as={Fragment} show={isOpen}>
      <DialogHeadless
        className={clsx(wrapperClassName)}
        as="div"
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={clsx(
              'fixed bottom-0 left-0 right-0 top-0 z-30 flex bg-slate-700 opacity-70 desktop:hidden'
            )}
          />
        </Transition.Child>
        <div
          className={clsx(
            'fixed bottom-0 right-auto z-50 w-screen duration-100 desktop:-bottom-full',
            className
          )}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-transform duration-500"
            enterFrom="translate-y-full"
            enterTo="translate-y-0"
            leave="transition-transform duration-500"
            leaveFrom="translate-y-0"
            leaveTo="translate-y-full"
          >
            <DialogHeadless.Panel className="h-full w-full rounded-t-xl bg-white shadow-lg">
              <div className={clsx(!withoutPadding && 'px-5')}>
                {title && (
                  <DialogHeadless.Title
                    className="flex justify-between border-b-[0.5px] border-slate-300 py-4 "
                    as="h3"
                  >
                    <div className="flex text-2xl font-medium">{title}</div>
                    <button
                      className="text-gray-900 duration-150 hover:text-gray-400"
                      onClick={onClose}
                    >
                      <Icon
                        className="h-6 w-6 text-primary-600"
                        name="close-24"
                      />
                    </button>
                  </DialogHeadless.Title>
                )}
                <div
                  className={clsx(
                    'no-scrollbar h-full overflow-y-auto',
                    !withoutPadding && 'pb-5 pt-4'
                  )}
                >
                  {children}
                </div>
              </div>
              {buttonLabel && (
                <div className="border-t-[0.5px] border-slate-300 px-4 py-3 desktop:px-3">
                  <Button className="w-full border-none" onClick={onClose}>
                    {buttonLabel}
                  </Button>
                </div>
              )}
            </DialogHeadless.Panel>
          </Transition.Child>
        </div>
      </DialogHeadless>
    </Transition>
  )
}
