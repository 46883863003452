import Router from 'next/router'

import { refreshTokens } from '@/lib/api/users'
import {
  API_BASE_URL,
  API_DEV_BASE_URL,
  DEV_HOSTNAMES,
  VERCEL_HOSTNAME_SUFFIX
} from '@/lib/constants'
import { buildQuery } from '@/lib/utils'

import useAuthStore from '@/hooks/stores/useAuthStore'

export class APIError extends Error {
  status: number
  code: string

  constructor(status: number, code: string, message: string) {
    super(message)
    this.status = status
    this.code = code
  }
}

export async function fetchAPI(
  method: string,
  path: string,
  params: Record<string, any> = {},
  body?: any
): Promise<any> {
  const { accessToken, isDemo } = useAuthStore.getState()

  const headers: Record<string, string> = {}
  const hasFormData = body instanceof FormData

  if (method === 'POST' && !hasFormData) {
    headers['Content-Type'] = 'application/json'
  }

  if (accessToken || isDemo) {
    headers['X-Auth-Token'] = isDemo ? 'demo' : accessToken
  }

  const query = buildQuery({
    language: Router.locale || 'en',
    ...params
  })

  const url = `${getBaseURL()}${path}${query}`

  const options: RequestInit = { method, headers }

  if (body) {
    options.body = hasFormData ? body : JSON.stringify(body)
  }

  const response = await fetch(url, options)
  const data = await response.json()

  if (response.status === 401) {
    const { refreshToken, updatedAt } = useAuthStore.getState()

    if (!refreshToken || updatedAt > Date.now() - 60 * 1000 /* 1 minute */) {
      throw new APIError(
        response.status,
        data?.error?.code,
        data?.error?.message
      )
    }

    const { accessToken, refreshToken: newRefreshToken } =
      await refreshTokens(refreshToken)

    useAuthStore.setState({
      accessToken,
      refreshToken: newRefreshToken
    })

    return fetchAPI(method, path, params, body)
  }

  if (response.status >= 400) {
    throw new APIError(response.status, data?.error?.code, data?.error?.message)
  }

  return data
}

export function getBaseURL() {
  return DEV_HOSTNAMES.includes(window.location.hostname) ||
    window.location.hostname?.endsWith(VERCEL_HOSTNAME_SUFFIX)
    ? API_DEV_BASE_URL
    : API_BASE_URL
}
