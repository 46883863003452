import React from 'react'

import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'

import { Combobox, Listbox } from '@headlessui/react'

interface SelectOptionProps {
  id?: number | string
  title: string | JSX.Element
  value: any
  isSelected?: boolean
  disabled?: boolean
  as: typeof Listbox.Option | typeof Combobox.Option
}

const SelectOption = ({
  id,
  title,
  value,
  isSelected,
  disabled,
  as: Component
}: SelectOptionProps) => {
  return (
    <Component
      className={clsx(
        'relative z-20 select-none overflow-hidden text-ellipsis p-2 pl-4',
        !disabled && 'cursor-pointer hover:bg-gray-100'
      )}
      disabled={!!disabled}
      value={value}
    >
      {({ selected }) => (
        <span
          className={clsx(
            'text-zink whitespace-nowrap text-base',
            'transition-all duration-150 ease-linear',
            (isSelected === undefined ? selected : isSelected)
              ? 'font-medium'
              : 'font-normal'
          )}
        >
          {typeof title === 'string' && id ? (
            <FormattedMessage
              {...{
                id: id as string,
                defaultMessage: title
              }}
            />
          ) : (
            title
          )}
        </span>
      )}
    </Component>
  )
}

export default SelectOption
