import React, { Fragment, PropsWithChildren } from 'react'

import clsx from 'clsx'

import { Dialog as DialogHeadless, Transition } from '@headlessui/react'

import Icon from '@/components/Icon'

export interface SpecificDialogProps {
  isOpen: boolean
  onClose: () => void
}

interface DialogProps extends SpecificDialogProps {
  title?: string | JSX.Element
  className?: string
  wrapperClassName?: string
  titleClassName?: string
  hasTitle?: boolean
  hasBackBtn?: boolean
  hideCloseIcon?: boolean
  withoutPadding?: boolean
  onBackBtnClick?: () => void
}

export const Dialog = ({
  title,
  onClose,
  isOpen,
  className,
  wrapperClassName,
  titleClassName,
  hasTitle = true,
  hideCloseIcon = false,
  withoutPadding = false,
  hasBackBtn,
  onBackBtnClick,
  children
}: PropsWithChildren<DialogProps>) => {
  return (
    <>
      <Transition appear as={Fragment} show={isOpen}>
        <DialogHeadless
          className={clsx(wrapperClassName)}
          as="div"
          onClose={onClose}
        >
          <Transition.Child
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={clsx('fixed inset-0 z-50 flex tablet:bg-slate-700')}
            />
          </Transition.Child>
          <div
            className={clsx(
              'fixed right-auto top-0 z-50 h-screen w-screen duration-100',
              'tablet:left-1/2 tablet:top-1/2 tablet:h-auto tablet:w-full',
              'tablet:max-w-sm tablet:-translate-x-1/2 tablet:-translate-y-1/2',
              className
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="scale-95 opacity-0"
              enterTo="scale-100 opacity-100"
              leave="duration-100 ease-in"
              leaveFrom="scale-100 opacity-100"
              leaveTo="scale-95 opacity-0"
            >
              <DialogHeadless.Panel className="flex h-full max-h-[100dvh] w-full flex-col bg-white shadow-lg tablet:max-h-[calc(100dvh-48px)] tablet:rounded-2xl">
                {hasTitle && (
                  <DialogHeadless.Title
                    className={clsx(
                      'flex flex-none items-center justify-between px-5 py-4',
                      titleClassName
                    )}
                    as="div"
                  >
                    {hasBackBtn ? (
                      <div className="flex items-center gap-3">
                        <button
                          className="h-6 w-6 duration-150"
                          onClick={onBackBtnClick}
                        >
                          <Icon
                            className="h-6 w-6 text-primary-600 hover:text-gray-400"
                            name="arrow-back-blue-24"
                          />
                        </button>
                        <div className="mr-2 py-2 text-3xl font-medium leading-8">
                          {title}
                        </div>
                      </div>
                    ) : (
                      <div className="leading-2xl mr-2 grow text-2xl font-medium">
                        {title}
                      </div>
                    )}
                    <button
                      className={clsx(
                        'h-6 w-6 text-primary-600 duration-150 hover:text-gray-400 tablet:block',
                        hideCloseIcon && '!hidden'
                      )}
                      onClick={onClose}
                    >
                      <Icon className="h-6 w-6" name="close-24" />
                    </button>
                  </DialogHeadless.Title>
                )}
                <div
                  className={clsx(
                    'no-scrollbar min-h-0 flex-grow pt-1',
                    !withoutPadding && 'px-5 pb-5'
                  )}
                >
                  {children}
                </div>
              </DialogHeadless.Panel>
            </Transition.Child>
          </div>
        </DialogHeadless>
      </Transition>
    </>
  )
}
