import React from 'react'

import clsx from 'clsx'

import { Listbox } from '@headlessui/react'

import CreditCardTitle from '@/components/CreditCardSelect/CreditCardTitle'
import Icon from '@/components/Icon'

export const BALANCE_PAYMENT_METHOD = { value: 'balance', icon: 'cash' }

export const DefaultPaymentMethods = () => {
  return (
    <div>
      <Listbox.Option
        className="relative z-20 cursor-pointer select-none px-3 py-2 hover:bg-gray-100"
        value="balance"
      >
        {({ selected }) => (
          <div
            className={clsx(
              'text-zink flex justify-between overflow-hidden whitespace-nowrap text-base',
              'transition-all duration-150 ease-linear',
              selected ? 'font-medium' : 'font-normal'
            )}
          >
            <CreditCardTitle paymentMethod={BALANCE_PAYMENT_METHOD} />
            {selected && (
              <Icon className="h-5 w-5 text-slate-300" name="check-20" />
            )}
          </div>
        )}
      </Listbox.Option>
    </div>
  )
}
