import React, { Fragment, PropsWithChildren } from 'react'

import clsx from 'clsx'

import { Transition } from '@headlessui/react'

interface OnboardingProps {
  isOnboardingOpen: boolean
  isStepOpen: boolean
  isVideoStep?: boolean
}

export interface OnboardingChildProps {
  onClick: () => void
}

const OnboardStep = ({
  isStepOpen,
  isOnboardingOpen,
  children,
  isVideoStep
}: PropsWithChildren<OnboardingProps>) => {
  return (
    <>
      <Transition appear as={Fragment} show={isOnboardingOpen && isStepOpen}>
        <Transition.Child
          as={Fragment}
          enter="duration-300 ease-out"
          enterFrom="scale-95 opacity-0"
          enterTo="scale-100 opacity-100"
          leave="duration-200 ease-in"
          leaveFrom="scale-100 opacity-100"
          leaveTo="scale-95 opacity-0"
        >
          <div
            className={clsx(
              'fixed right-auto top-0 z-50 h-screen w-screen duration-100',
              'tablet:left-1/2 tablet:top-1/2 tablet:h-auto tablet:w-full',
              'tablet:-translate-x-1/2 tablet:-translate-y-1/2',
              'h-full w-full bg-white shadow-lg tablet:rounded-2xl',
              isVideoStep
                ? 'tablet:!max-w-[712px] desktop:max-w-[732px]'
                : 'tablet:!max-w-[450px]'
            )}
          >
            <div className="no-scrollbar h-full">{children}</div>
          </div>
        </Transition.Child>
      </Transition>
    </>
  )
}

export default OnboardStep
