import Image from 'next/image'
import { useRouter } from 'next/router'
import React from 'react'

import { useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { formatCurrency } from '@/lib/utils'

import useUserInfo from '@/hooks/users/useUserInfo'

import Button from '@/components/Button'
import { Drawer } from '@/components/Drawer'
import { Dialog, SpecificDialogProps } from '@/components/dialogs/Dialog'
import Form from '@/components/form/Form'

export const PromocodeSuccessDialog = ({
  isOpen,
  onClose
}: SpecificDialogProps) => {
  const { locale } = useRouter()
  const { handleSubmit } = useForm()
  const { bonusBalance, currency } = useUserInfo()

  const PromocodeSuccessForm = () => (
    <Form
      className="-mt-1 flex flex-col items-center pb-8"
      onSubmit={handleSubmit(onClose)}
    >
      <Image
        className="w-full"
        alt="Money background"
        height={138}
        priority
        src="/images/dog-coin.svg"
        width={241}
      />
      <span className="pt-2 text-3xl font-medium">
        + {bonusBalance && formatCurrency(bonusBalance * 100, currency, locale)}
      </span>
      <span className="max-w-[315px] pb-4 pt-2 text-center">
        <FormattedMessage
          {...{
            id: 'onboard.earn.dialog.text',
            defaultMessage:
              'Средства по промокоду успешно зачислены на ваш бонусный баланс'
          }}
        />
      </span>
      <Button variant="secondary">
        <FormattedMessage
          {...{
            id: 'button.ready',
            defaultMessage: 'Готово'
          }}
        />
      </Button>
    </Form>
  )

  return (
    <>
      <Dialog
        hasTitle={false}
        withoutPadding
        wrapperClassName="invisible pointer-events-none phone-lg:visible phone-lg:pointer-events-auto"
        {...{ isOpen, onClose }}
      >
        <PromocodeSuccessForm />
      </Dialog>
      <Drawer
        wrapperClassName="visible pointer-events-auto phone-lg:invisible phone-lg:pointer-events-none"
        {...{ isOpen, onClose }}
        withoutPadding
      >
        <PromocodeSuccessForm />
      </Drawer>
    </>
  )
}
