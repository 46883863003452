import React, { memo } from 'react'

import clsx from 'clsx'
import { useIntl } from 'react-intl'

import { CardIssuer } from '@/lib/types'

import Icon from '@/components/Icon'

import { CardSelectOption } from '.'

const CreditCardTitle = ({
  paymentMethod
}: {
  paymentMethod: CardSelectOption
}) => {
  const { formatMessage } = useIntl()

  const { issuer, lastDigits, icon, value, translationId } = paymentMethod

  return (
    <div className="flex w-11/12 items-center justify-between">
      <div className="flex items-center gap-3">
        <Icon
          className={clsx(issuer ? 'h-4 w-8' : 'h-5 w-5')}
          name={issuer ? getCardIconName(issuer) : `${icon}-20`}
        />
        {issuer ? (
          <div className="flex items-center gap-2 text-x-base font-normal">
            <Icon className="h-5 w-5 text-slate-500" name="dots-20" />
            {lastDigits}
          </div>
        ) : (
          <span className="text-x-base font-normal">
            {formatMessage({
              id: translationId || `payment.methods.${value}`
            })}
          </span>
        )}
      </div>
    </div>
  )
}

const getCardIconName = (issuer: CardIssuer | '') => {
  switch (issuer) {
    case 'visa':
      return 'visa-24'
    case 'mastercard':
      return 'mastercard-24'
    case 'mir':
      return 'mircard-24'
    default:
      return 'card-24'
  }
}

export default memo(CreditCardTitle)
