import { useState } from 'react'

import { useIntl } from 'react-intl'

import { useFlashStore } from '@/hooks/stores/useFlashStore'

const useLoading = (initialState?: boolean) => {
  const { formatMessage } = useIntl()
  const [isLoading, setIsLoading] = useState<boolean>(!!initialState)
  const { setErrorMessage } = useFlashStore()

  const defaultErrorMessage = formatMessage({
    id: 'error.unexpected',
    defaultMessage: 'Неожиданная ошибка'
  })

  async function tryLoad(
    resolveFunction: () => Promise<any>,
    rejectFunction: () => any = () => null
  ) {
    try {
      setIsLoading(true)
      await resolveFunction()
    } catch (error: any) {
      setErrorMessage(error.message || defaultErrorMessage)
      console.log(error)
      await rejectFunction()
    } finally {
      setIsLoading(false)
    }
  }

  return { isLoading, setIsLoading, tryLoad }
}

export default useLoading
