import { create } from 'zustand'

const MESSAGE_TIMEOUT = 5_000

interface FlashState {
  infoMessage: string
  errorMessage: string
  successMessage: string
  setInfoMessage: (message: string) => void
  setErrorMessage: (message: string) => void
  setSuccessMessage: (message: string) => void
}

export const useFlashStore = create<FlashState>((set) => ({
  infoMessage: '',
  errorMessage: '',
  successMessage: '',
  setInfoMessage: (message: string) => {
    set({ infoMessage: message })
    setTimeout(() => set({ infoMessage: '' }), MESSAGE_TIMEOUT)
  },
  setErrorMessage: (message: string) => {
    set({ errorMessage: message })
    setTimeout(() => set({ errorMessage: '' }), MESSAGE_TIMEOUT)
  },
  setSuccessMessage: (message: string) => {
    set({ successMessage: message })
    setTimeout(() => set({ successMessage: '' }), MESSAGE_TIMEOUT)
  }
}))
