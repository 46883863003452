import { useRouter } from 'next/router'
import React from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { FieldValues, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { replenish } from '@/lib/api/marketplace'
import { trackEvent } from '@/lib/api/metrics'
import { ReplenishData, ReplenishSchema } from '@/lib/schemes'
import { PaymentMethod } from '@/lib/types'
import { formatCurrency } from '@/lib/utils'

import useOrderEstimate from '@/hooks/marketplace/useOrderEstimate'
import useAuthStore from '@/hooks/stores/useAuthStore'
import useCookies from '@/hooks/stores/useCookies'
import { useSignInStore } from '@/hooks/stores/useSignInStore'
import { useCurrencySymbol } from '@/hooks/useCurrencySymbol'
import useLoading from '@/hooks/useLoading'
import useUserInfo from '@/hooks/users/useUserInfo'

import Button from '@/components/Button'
import CreditCardSelect from '@/components/CreditCardSelect'
import Input from '@/components/Input'
import Label from '@/components/Label'
import { Dialog, SpecificDialogProps } from '@/components/dialogs/Dialog'
import ShopDiscountTag from '@/components/features/shop/ShopDiscountTag'
import Form from '@/components/form/Form'
import FormActions from '@/components/form/FormActions'
import FormField from '@/components/form/FormField'
import RowSkeleton from '@/components/skeletons/RowSkeleton'

interface ReplenishDialogProps extends SpecificDialogProps {
  className?: string
}

const ReplenishDialog = ({
  isOpen,
  onClose,
  className
}: ReplenishDialogProps) => {
  const router = useRouter()
  const { isDemo } = useAuthStore()
  const { sessionId } = useCookies()
  const { currency } = useUserInfo()
  const { formatMessage } = useIntl()
  const queryClient = useQueryClient()
  const { symbol } = useCurrencySymbol()
  const { openDemoModal } = useSignInStore()
  const { isLoading, tryLoad } = useLoading()

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm<ReplenishData>({
    resolver: zodResolver(ReplenishSchema),
    defaultValues: {
      paymentMethod: null
    }
  })

  const advance = watch('advance')
  const paymentMethod = watch('paymentMethod')
  const isCryptoPayment = paymentMethod === 'usdt_trc20'

  const { estimate, isLoading: isEstimateLoading } = useOrderEstimate({
    currency,
    advance: advance || 0,
    paymentMethod: paymentMethod as PaymentMethod,
    enabled: isCryptoPayment && !!advance
  })

  const { bonusReplenish, bonusReplenishPercentage } = estimate || {}

  const onDialogClose = () => {
    reset()
    onClose()
  }

  const onSubmit = async (data: FieldValues) => {
    if (isDemo) {
      openDemoModal()
      setTimeout(onClose, 0)
    } else {
      const { advance, paymentMethod } = data
      await tryLoad(async () => {
        const { paymentURL } = await replenish({
          advance,
          currency,
          paymentMethod,
          metricsId: sessionId
        })
        onClose()
        reset()

        await trackEvent('payment_redirect')
        await router.push(paymentURL)
      })

      await queryClient.invalidateQueries({
        queryKey: ['user-info']
      })
      await queryClient.invalidateQueries({
        queryKey: ['payments']
      })
    }
  }

  return (
    <Dialog
      className={clsx(className || 'tablet:!max-w-[500px]')}
      title={formatMessage({
        id: 'dialog.balance.title',
        defaultMessage: 'Пополнение баланса'
      })}
      {...{ isOpen, onClose: onDialogClose }}
    >
      <Form className="gap-5 tablet:gap-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-[22px]">
          <FormField error={errors.advance}>
            <Input
              type="number"
              inputMode="decimal"
              step="0.00000001"
              suffix={symbol}
              placeholder={formatMessage({
                id: 'dialog.balance.text',
                defaultMessage: 'Сумма пополнения'
              })}
              {...register('advance')}
            />
          </FormField>
          <FormField className="gap-2.5" error={errors.paymentMethod}>
            <Label className="text-x-md leading-x-md">
              <FormattedMessage
                {...{
                  id: 'payment.methods',
                  defaultMessage: 'Способ оплаты'
                }}
              />
            </Label>
            <CreditCardSelect
              className="w-full"
              hasBalance={false}
              isReplenish
              value={watch('paymentMethod')}
              placeholder={formatMessage({
                id: 'payment.methods.choose',
                defaultMessage: 'Выберите способ оплаты'
              })}
              {...register('paymentMethod')}
            />
          </FormField>
          {isCryptoPayment && !!advance && (
            <div className="flex justify-between">
              <span>
                <FormattedMessage
                  {...{
                    id: 'dialog.balance.bonus.replenish',
                    defaultMessage: 'Бонусное пополнение'
                  }}
                />
              </span>
              {isEstimateLoading ? (
                <RowSkeleton className="h-6 w-32" />
              ) : (
                <div className="flex items-center gap-2">
                  <ShopDiscountTag>
                    +{bonusReplenishPercentage}%
                  </ShopDiscountTag>
                  <span className="font-medium">
                    +
                    {!!bonusReplenish &&
                      formatCurrency(bonusReplenish, currency, router.locale)}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
        <FormActions className="grid-cols-1">
          <Button className="min-h-11 w-full" isLoading={isLoading}>
            <FormattedMessage
              {...{
                id: 'button.replenish',
                defaultMessage: 'Пополнить'
              }}
            />
          </Button>
        </FormActions>
        {isCryptoPayment && !!advance && (
          <div className="-mt-[10px] inline-flex self-center text-x-sm">
            <FormattedMessage
              {...{
                id: 'dialog.balance.bonus.replenish.info',
                defaultMessage: 'На ваш баланс поступит'
              }}
            />{' '}
            {isEstimateLoading ? (
              <RowSkeleton className="ml-2 h-5 w-18" />
            ) : (
              formatCurrency(
                advance * 100 + (bonusReplenish || 0),
                currency,
                router.locale
              )
            )}
          </div>
        )}
      </Form>
    </Dialog>
  )
}

export default ReplenishDialog
