import {
  CreditCard,
  CreditCardDTO,
  Device,
  DeviceDTO,
  MarketplaceDevices,
  Order,
  OrderEstimate,
  PaymentMethod,
  creditCardsFromDTO,
  deviceFromDTO,
  estimateFromDTO,
  marketplaceDevicesFromDTO
} from '@/lib/types'

import { DeviceFilterStore } from '@/hooks/stores/useDeviceFilterStore'

import { fetchAPI } from '.'

export async function getDevicesForSale({
  sort,
  order,
  page,
  currency,
  preorder,
  locationId,
  totalPrice,
  pricePerTH
}: Partial<DeviceFilterStore> & {
  page: number
  currency: string
}): Promise<MarketplaceDevices> {
  const data = await fetchAPI(
    'POST',
    'marketplace/devices/search',
    { page, currency },
    {
      sort,
      order,
      preorder,
      location_id: locationId,
      min_price: totalPrice?.from && Math.round(totalPrice?.from),
      max_price: totalPrice?.to && Math.ceil(totalPrice?.to),
      min_price_per_th: pricePerTH?.from && Math.round(pricePerTH?.from),
      max_price_per_th: pricePerTH?.to && Math.ceil(pricePerTH?.to)
    }
  )

  return marketplaceDevicesFromDTO(data)
}

export async function createOrder({
  id,
  hashrate,
  paymentMethod,
  advance,
  currency,
  creditCardId,
  deviceCount,
  installment,
  useBonusBalance,
  metricsId
}: {
  id: number
  hashrate?: number
  paymentMethod: PaymentMethod
  advance: number
  currency: string
  creditCardId?: number
  deviceCount?: number
  installment?: boolean
  useBonusBalance: boolean
  metricsId?: string
}): Promise<Order> {
  const data = await fetchAPI(
    'POST',
    `marketplace/orders`,
    {},
    {
      device_id: id,
      hashrate,
      payment_method: paymentMethod,
      advance: advance * 100,
      currency,
      credit_card_id: creditCardId,
      device_count: deviceCount,
      installment,
      use_bonus_balance: useBonusBalance,
      metrics_id: metricsId
    }
  )

  return { orderNumber: data?.order_number, paymentURL: data?.payment_url }
}

export async function getOrderEstimate({
  id,
  hashrate,
  advance,
  currency,
  installment,
  deviceCount,
  useBonusBalance,
  paymentMethod
}: {
  id?: number
  hashrate?: number
  advance: number
  currency: string
  installment?: boolean
  deviceCount?: number
  paymentMethod?: PaymentMethod | null
  useBonusBalance?: boolean
}): Promise<OrderEstimate> {
  const data = await fetchAPI(
    'POST',
    'marketplace/orders/estimate',
    {},
    {
      ...(id && { device_id: id }),
      hashrate,
      advance: advance * 100,
      currency,
      installment,
      device_count: deviceCount,
      use_bonus_balance: useBonusBalance,
      payment_method: paymentMethod
    }
  )

  return estimateFromDTO(data)
}

export async function replenish({
  paymentMethod,
  advance,
  currency,
  creditCardId,
  metricsId
}: {
  paymentMethod: PaymentMethod
  advance: number
  currency: string
  creditCardId?: number
  metricsId?: string
}): Promise<Order> {
  const { order_number, payment_url } = await fetchAPI(
    'POST',
    `marketplace/orders`,
    {},
    {
      payment_method: paymentMethod,
      advance: advance * 100,
      currency,
      credit_card_id: creditCardId,
      metrics_id: metricsId
    }
  )

  return { orderNumber: order_number, paymentURL: payment_url }
}

export async function repayInstallment({
  deviceId,
  amount,
  paymentMethod,
  currency,
  creditCardId,
  installment
}: {
  deviceId: number
  paymentMethod: PaymentMethod
  amount: number
  currency: string
  installment?: boolean
  creditCardId?: number
}): Promise<Order> {
  const { order_number, payment_url } = await fetchAPI(
    'POST',
    `marketplace/orders`,
    {},
    {
      device_id: deviceId,
      payment_method: paymentMethod,
      amount,
      currency,
      installment,
      credit_card_id: creditCardId
    }
  )

  return { orderNumber: order_number, paymentURL: payment_url }
}

export async function getCreditCards(): Promise<CreditCard[]> {
  const cards = (await fetchAPI('GET', 'users/credit_cards')) as CreditCardDTO[]

  return cards.map((card) => creditCardsFromDTO(card))
}

export async function getSimilarDevices({
  id,
  page
}: {
  id: number
  page?: number
}): Promise<{ devices: Partial<Device>[]; total: number }> {
  const { devices, total } = await fetchAPI(
    'GET',
    `marketplace/devices/${id}/similar`,
    { page }
  )

  return {
    devices: devices.map((device: DeviceDTO) => deviceFromDTO(device)),
    total
  }
}
