import Image from 'next/image'
import React from 'react'

import { FormattedMessage } from 'react-intl'

import Button from '@/components/Button'
import Form from '@/components/form/Form'

import { OnboardingChildProps } from './OnboardStep'

const OnboardWelcomeStep = ({ onClick }: OnboardingChildProps) => {
  return (
    <Form className="items-center justify-center px-3 py-6 tablet:px-6">
      <div className="mb-6 h-[224px] flex-none">
        <Image
          className="h-full w-auto"
          alt="confirm"
          height={160}
          src={`/images/dog-flag.svg`}
          width={106}
        />
      </div>
      <h3 className="mb-4 text-center text-xl font-medium leading-xl tablet:text-2xl tablet:leading-xs">
        <FormattedMessage
          {...{
            id: 'onboard.welcome.title',
            defaultMessage: 'Добро пожаловать {br} в Asterbit',
            values: {
              br: <br />
            }
          }}
        />
      </h3>
      <p className="mb-8 text-center text-x-base leading-x-base tablet:mb-[30px]">
        <FormattedMessage
          {...{
            id: 'onboard.welcome.text',
            defaultMessage:
              'Начните инвестировать в прозрачный и понятный {br} бизнес с высокой маржинальностью',
            values: {
              br: <br />
            }
          }}
        />
      </p>
      <Button
        className="min-h-11 w-full tablet:w-fit tablet:min-w-[180px]"
        onClick={onClick}
        tag="div"
        variant="secondary"
      >
        <FormattedMessage {...{ id: 'button.next', defaultMessage: 'Далее' }} />
      </Button>
    </Form>
  )
}

export default OnboardWelcomeStep
