import React, { Fragment, PropsWithChildren, Ref, forwardRef } from 'react'

import clsx from 'clsx'

import { autoPlacement, useFloating } from '@floating-ui/react'
import { Listbox, Transition } from '@headlessui/react'

import { CardIssuer } from '@/lib/types'

import useUserInfo from '@/hooks/users/useUserInfo'

import CreditCardTitle from '@/components/CreditCardSelect/CreditCardTitle'
import { BALANCE_PAYMENT_METHOD } from '@/components/CreditCardSelect/DefaultPaymentMethods'
import Icon from '@/components/Icon'
import SelectArrow from '@/components/selectbox/SelectArrow'

export interface CardSelectOption {
  value: string | number
  icon?: string
  translationId?: string
  issuer?: CardIssuer
  lastDigits?: string
}

interface CreditCardSelectProps {
  className?: string
  onChange?: (value: any) => void
  defaultValue?: any
  icon?: string
  value: any
  displayArrow?: boolean
  placeholder?: string
  hasBalance?: boolean
  name?: string
  isReplenish?: boolean
}

const CreditCardSelect = (
  {
    className,
    onChange,
    defaultValue,
    icon,
    value,
    name,
    placeholder,
    isReplenish,
    hasBalance = true,
    displayArrow = true
  }: PropsWithChildren<CreditCardSelectProps>,
  ref: Ref<HTMLDivElement>
) => {
  const { country } = useUserInfo()
  const { refs, floatingStyles } = useFloating({
    middleware: [
      autoPlacement({
        alignment: 'start',
        allowedPlacements: ['bottom-start', 'top-start']
      })
    ]
  })

  const isRussian = country === 'RU' && false // TODO: re-enable

  const PAYMENT_METHODS = [
    hasBalance && {
      value: 'balance',
      icon: 'cash'
    },
    isRussian && {
      value: 'card_ru',
      icon: 'rub'
    },
    {
      value: 'card',
      icon: 'usd',
      translationId: isRussian
        ? 'payment.methods.card.other.ru'
        : 'payment.methods.card.other'
    },
    {
      value: 'usdt_trc20',
      icon: 'usdt',
      translationId: isReplenish
        ? 'payment.methods.usdt.replenish'
        : 'payment.methods.usdt'
    }
  ].filter(Boolean) as CardSelectOption[]

  const onChangeHandler = (value: any) => {
    if (onChange) {
      onChange({ target: { value, name } })
    }
  }

  const selectedCard = findSelectedOption({ value, options: PAYMENT_METHODS })

  return (
    <div className={clsx('select-none', className)}>
      <Listbox
        as={Fragment}
        defaultValue={defaultValue}
        onChange={onChangeHandler}
        value={value || null}
      >
        <div className="relative">
          <Listbox.Button
            className={({ open }) =>
              clsx(
                'relative flex h-11 w-full cursor-pointer items-center justify-between',
                'whitespace-nowrap rounded-xl bg-slate-100 py-2.5 pl-3',
                'transition-all duration-150 ease-linear',
                'rounded-lg border-[0.5px] border-slate-300 bg-white',
                'group-[.error]:!border-red-500',
                open && 'bg-slate-200'
              )
            }
            ref={refs.setReference}
          >
            {({ open }) => (
              <>
                <div
                  className={clsx(
                    'medium-text flex items-center gap-3 overflow-hidden',
                    'w-full whitespace-nowrap text-x-base leading-5',
                    selectedCard?.value ? 'color-sub' : 'text-secondary-500'
                  )}
                  ref={ref}
                >
                  {icon && <Icon className="h-2.5 w-8" name={icon} />}
                  {selectedCard ? (
                    <CreditCardTitle paymentMethod={selectedCard} />
                  ) : (
                    placeholder
                  )}
                </div>
                {displayArrow && <SelectArrow className="mr-2" open={open} />}
              </>
            )}
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter="transition-opacity duration-100 ease-in"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-100 ease-out"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={clsx(
                'scroll-wrap absolute z-20 my-2 flex max-h-60 w-max min-w-[100%] flex-col',
                'gap-2 overflow-auto rounded-md bg-white py-2 text-lg shadow-lg sm:text-sm'
              )}
              ref={refs.setFloating}
              style={floatingStyles}
            >
              {PAYMENT_METHODS.map((method) => (
                <Listbox.Option
                  className="relative z-20 cursor-pointer select-none px-3 py-2 hover:bg-gray-100"
                  key={method.value}
                  value={method.value}
                >
                  {({ selected }) => (
                    <span
                      className={clsx(
                        'text-zink overflow-hidden whitespace-nowrap text-base',
                        'transition-all duration-150 ease-linear',
                        selected ? 'font-medium' : 'font-normal'
                      )}
                    >
                      <CreditCardTitle paymentMethod={method} />
                    </span>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

const findSelectedOption = ({
  value,
  options
}: {
  value: any
  options: CardSelectOption[]
}) => {
  const formattedOptions = [...options, BALANCE_PAYMENT_METHOD]

  const selectedOption = formattedOptions.find(
    (option) => option.value === value
  )

  return selectedOption
}

export default forwardRef(CreditCardSelect)
