import { HTMLProps } from 'react'

import clsx from 'clsx'

import CountrySelect from '@/components/CountrySelect'
import Input from '@/components/Input'

interface PhoneInputProps extends HTMLProps<HTMLInputElement> {
  countrySelectProps?: any
  inputProps?: any
  inputClassName?: string
  buttonClassName?: string
}

const PhoneInput = ({
  className,
  countrySelectProps,
  inputProps,
  inputClassName,
  buttonClassName
}: PhoneInputProps) => {
  return (
    <div
      className={clsx(
        'flex items-center rounded-lg !border border-slate-300',
        'group-[.error]:!border-red-500',
        className
      )}
    >
      <CountrySelect
        {...countrySelectProps}
        className="flex-none"
        buttonClassName={clsx('bg-white', buttonClassName)}
      />
      <Input
        {...{
          ...inputProps,
          type: 'tel',
          placeholder: 'XXX XXX XXXX',
          className: clsx(
            'h-full rounded-l-none rounded-r-lg !border-none bg-white text-x-base focus:bg-slate-600',
            inputClassName
          )
        }}
      />
    </div>
  )
}

export default PhoneInput
