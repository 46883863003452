import { isArray, isEmpty, isNil, map, omitBy } from 'lodash'
import { parsePhoneNumber } from 'react-phone-number-input'

import { CryptoCurrency } from '@/lib/types'

import { SelectOption } from '@/components/SelectBox'

import { AsYouType } from 'libphonenumber-js'
import phoneMetadata from 'libphonenumber-js/metadata.min.json'

export const buildQuery = (params: any) => {
  const normalizedParams = omitBy(params, isNil)

  if (isEmpty(normalizedParams)) {
    return ''
  }

  const query = new URLSearchParams({
    ...normalizedParams
  })

  return `?${query}`
}

// Polyfill for iOS Safari
// https://stackoverflow.com/a/2117523/2800218
export function randomUUID(): string {
  if ('randomUUID' in crypto) {
    return crypto.randomUUID()
  }

  return ([1e7] + '' + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      Number(c) ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (Number(c) / 4)))
    ).toString(16)
  )
}

export function getCountryFromCode(code: number) {
  const countryCodes = phoneMetadata.country_calling_codes[code]

  return countryCodes ? countryCodes[0] : null
}

export function isValidPhone({
  countryCode,
  phone
}: {
  countryCode?: number
  phone?: string
}) {
  if (!countryCode || !phone) {
    return false
  }

  const asYouType = new AsYouType()
  asYouType.input(`+${countryCode}${phone}`)
  return asYouType.isPossible()
}

export const formatPhone = (countryCode: string | number, phone: string) => {
  return parsePhoneNumber(`+${countryCode}${phone}`)?.formatInternational()
}

export const formatCurrency = (
  value: number | string,
  currency: string,
  locale?: string
): string => {
  if (['BTC', 'USDT'].includes(currency)) {
    const formattedValue = new Intl.NumberFormat(locale ?? 'ru', {
      maximumFractionDigits: currency === 'USDT' ? 2 : 8
    }).format(Number(value))

    return `${formattedValue} ${currency}`
  }

  const denominatedCoins = Number(value) / 100

  return new Intl.NumberFormat(locale ?? 'ru', {
    currency,
    style: 'currency',
    maximumFractionDigits: 2
  }).format(denominatedCoins)
}

export const buildSelectOptions = (object: any): SelectOption[] =>
  isEmpty(object)
    ? []
    : map(object, (title, value) => ({
        value: isArray(object) ? title : value,
        title: String(title)
      }))

export const formatAmount = (
  amount: number,
  currency: CryptoCurrency,
  _locale?: string
) => {
  return currency === 'USDT' ? amount.toFixed(2) : amount.toFixed(8)
}
