import { DeviceType } from '@/lib/types'

interface FooterLink {
  id: string
  text: string
  href: any
  screens: DeviceType[]
  target?: boolean
}

export const useFooterData = () => {
  const FOOTER_DATA: FooterLink[] = [
    {
      id: 'footer.privacyPolicy',
      text: 'Политика конфиденциальности',
      target: true,
      href: {
        pathname: '/docs/[name]',
        query: { name: 'privacy_policy' }
      },
      screens: ['desktop', 'mobile']
    },
    {
      id: 'footer.termsOfService',
      text: 'Правила пользования',
      target: true,
      href: {
        pathname: '/docs/[name]',
        query: { name: 'terms_of_service' }
      },
      screens: ['desktop', 'mobile']
    },
    {
      id: 'page.support',
      text: 'Помощь',
      href: '/support',
      screens: ['desktop']
    }
  ]

  return { FOOTER_DATA }
}
