declare global {
  interface JivoCustomField {
    content: string
    title?: string
    link?: string
  }

  interface Window {
    jivo_api: {
      setContactInfo: ({
        email,
        phone
      }: {
        email?: string
        phone?: string
      }) => void
      setCustomData: (data: JivoCustomField[]) => void
    }
    jivo_init: () => void
    jivo_destroy: () => void
  }
}

function jivoInit() {
  if ('jivo_init' in window) {
    window.jivo_init()
  }
}

function jivoDestroy() {
  if ('jivo_destroy' in window) {
    window.jivo_destroy()
  }
}

function setJivoContactInfo({
  email,
  phone
}: {
  email?: string
  phone?: string
}) {
  if ('jivo_api' in window) {
    window.jivo_api.setContactInfo({ email, phone })
  }
}

function setJivoCustomData(data: JivoCustomField[]) {
  if ('jivo_api' in window) {
    window.jivo_api.setCustomData(data)
  }
}

export { jivoInit, jivoDestroy, setJivoContactInfo, setJivoCustomData }
