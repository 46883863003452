import { PropsWithChildren } from 'react'

import clsx from 'clsx'

const ShopDiscountTag = ({ children }: PropsWithChildren) => {
  return (
    <span
      className={clsx(
        'inline-flex h-5 items-center justify-center whitespace-nowrap rounded-md bg-green-100',
        'px-2 py-1 text-[11px] font-medium leading-x-sm text-green-600'
      )}
    >
      {children}
    </span>
  )
}

export default ShopDiscountTag
