import { ZodType, z } from 'zod'

import { isValidPhone } from '@/lib/utils'

export interface ConfirmAuthData
  extends Partial<PhoneData>,
    Partial<EmailData> {
  code: string
}

export interface PhoneData {
  phone: string
  countryCode: number
}

export interface EmailData {
  email: string
}

export interface AuthData extends Partial<PhoneData>, Partial<EmailData> {
  mode: 'email' | 'phone'
}

export interface AuthMethod extends Partial<PhoneData>, Partial<EmailData> {
  authToken: string
}

export interface AccountConfirmation {
  confirmationMethod: string
}

const PhoneDataSchema = z.object({
  countryCode: z.coerce.number().positive({ message: 'validation.required' }),
  phone: z.coerce
    .string()
    .regex(/^[0-9]+$/, { message: 'validation.invalid.phone' })
})

export const EmailDataSchema = z.object({
  email: z.coerce.string().email({ message: 'validation.invalid.email' })
})

export const SignInSchema: ZodType<AuthData> = z
  .discriminatedUnion('mode', [
    z.object({
      ...PhoneDataSchema.shape,
      mode: z.literal('phone')
    }),
    z.object({
      ...EmailDataSchema.shape,
      mode: z.literal('email')
    })
  ])
  .refine(
    (data) => {
      if (data.mode === 'phone') {
        const { countryCode, phone } = data
        return isValidPhone({ countryCode, phone })
      }
      return true
    },
    { message: 'validation.invalid.phone', path: ['phone'] }
  )

export const ConfirmAuthSchema: ZodType<ConfirmAuthData> = z.object({
  code: z.coerce
    .string()
    .min(6, { message: 'validation.invalid.code' })
    .max(6, { message: 'validation.invalid.code' }) // TODO: refactor
})

export const AccountConfirmationSchema: ZodType<AccountConfirmation> = z.object(
  {
    confirmationMethod: z.string({
      required_error: 'validation.required'
    })
  }
)
