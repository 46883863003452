import React from 'react'

import { clsx } from 'clsx'
import { differenceInDays } from 'date-fns'
import { FormattedMessage } from 'react-intl'

import { useDialog } from '@/hooks/dialogs/useDialog'
import useUserInfo from '@/hooks/users/useUserInfo'

import Icon from '@/components/Icon'
import ReplenishDialog from '@/components/dialogs/finances/ReplenishDialog'

const BalanceInfoMessage = ({ className }: { className?: string }) => {
  const { paidUntil } = useUserInfo()

  const {
    isOpen: isReplenishDialogOpen,
    closeModal: closeReplenishDialogModal,
    openModal: openReplenishDialogModal
  } = useDialog()

  const differenceDays =
    paidUntil && differenceInDays(new Date(paidUntil), new Date())
  const isSmallDifference = (differenceDays || 0) <= 3

  return (
    <div
      className={clsx(
        'flex max-w-screen-container items-center justify-between rounded-xl',
        'mx-auto mb-4 w-full px-3 py-2.5 text-sm tablet:text-x-base',
        'max-phone-lg:flex-col max-phone-lg:items-start',
        isSmallDifference ? 'bg-rose-500/[0.08]' : 'bg-amber-250',
        className
      )}
    >
      <div className="flex items-center gap-3">
        <Icon
          className={clsx(
            'inline h-4 w-4 flex-none cursor-pointer',
            isSmallDifference ? 'text-rose-500' : 'text-amber-500'
          )}
          name={isSmallDifference ? 'exclamation-24' : 'info-24'}
        />

        {isSmallDifference ? (
          <div className="phone-lg:max-tablet:max-w-[360px]">
            <FormattedMessage
              {...{
                id: 'balance.insufficient.title.one',
                defaultMessage:
                  '{differenceDays, plural, =0 {<span>Сегодня</span> вы можете потерять свое оборудование. Не забудьте пополнить баланс.} one {Через <span># день</span> вы можете потерять свое оборудование. Не забудьте пополнить баланс.} few {Через <span># дня</span> вы можете потерять свое оборудование. Не забудьте пополнить баланс.} many {Через <span># дней</span> вы можете потерять свое оборудование. Не забудьте пополнить баланс.}  other {Через <span># дней</span> вы можете потерять свое оборудование. Не забудьте пополнить баланс.}}',
                values: {
                  differenceDays,
                  span: (chunks) => (
                    <span className="font-medium">{chunks}</span>
                  )
                }
              }}
            />
          </div>
        ) : (
          <span>
            <FormattedMessage
              {...{
                id: 'balance.insufficient.title.two',
                defaultMessage:
                  '{differenceDays, plural, one {Средства на балансе закончатся через <span># день</span>} few {Средства на балансе закончатся через <span># дня</span>} many {Средства на балансе закончатся через <span># дней</span>}  other {Средства на балансе закончатся через <span># дней</span>}}',
                values: {
                  differenceDays,
                  span: (chunks) => (
                    <span className="font-medium">{chunks}</span>
                  )
                }
              }}
            />
          </span>
        )}
      </div>
      <button
        className="text-left font-medium text-primary-600 hover:text-primary-500 max-phone-lg:ml-7"
        onClick={openReplenishDialogModal}
      >
        <FormattedMessage
          {...{
            id: 'balance.insufficient.replenish',
            defaultMessage: 'Пополнить баланс'
          }}
        />
      </button>

      <ReplenishDialog
        {...{
          isOpen: isReplenishDialogOpen,
          onClose: closeReplenishDialogModal
        }}
      />
    </div>
  )
}

export default BalanceInfoMessage
