import Image from 'next/image'
import React from 'react'

import { FormattedMessage, useIntl } from 'react-intl'

import { PhoneData } from '@/lib/types'

import { useConfirmPhoneForm } from '@/hooks/auth/forms/useConfirmPhoneForm'
import { ActionType, LoginOption } from '@/hooks/auth/useAccountLoginDetails'

import Button from '@/components/Button'
import Input from '@/components/Input'
import Label from '@/components/Label'
import { Dialog, SpecificDialogProps } from '@/components/dialogs/Dialog'
import Form from '@/components/form/Form'
import FormActions from '@/components/form/FormActions'
import FormField from '@/components/form/FormField'

interface ConfirmPhoneDialogProps extends SpecificDialogProps {
  openPrevModal?: () => void
  phoneData: PhoneData
  openSignInModal?: () => void
  isAccountConfirm?: boolean
  action?: ActionType
  currentLoginData?: LoginOption | null
  authData?: LoginOption | null
}

const ConfirmPhoneDialog = ({
  isOpen,
  action,
  authData,
  phoneData,
  onClose,
  openPrevModal,
  openSignInModal,
  currentLoginData,
  isAccountConfirm
}: ConfirmPhoneDialogProps) => {
  const {
    form,
    countdown,
    isLoading,
    phoneTitle,
    onSubmit,
    handleResend,
    onBackBtnClick,
    handleCloseModal
  } = useConfirmPhoneForm({
    action,
    authData,
    enabled: isOpen,
    phoneData,
    openPrevModal,
    onClose,
    openSignInModal,
    isAccountConfirm,
    currentLoginData
  })

  const {
    handleSubmit,
    formState: { errors },
    register
  } = form

  const { formatMessage } = useIntl()

  return (
    <Dialog
      hasBackBtn={!!openPrevModal}
      title={phoneTitle || ''}
      {...{ isOpen, onClose: handleCloseModal, onBackBtnClick }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4 flex h-40 justify-center">
          <Image
            className="h-full w-auto"
            alt="confirm"
            height={160}
            src={`/images/dog-phone.svg`}
            width={106}
          />
        </div>
        <FormField
          className="mb-2.5 flex flex-col items-center text-center"
          error={errors.code}
        >
          <Label className="w-64 pb-3 font-normal">
            <FormattedMessage
              {...{
                id: 'dialog.confirmPhone.subtitle',
                defaultMessage: 'Введите код из SMS, отправленный на Ваш номер'
              }}
            />
          </Label>
          <Input
            className="text-center"
            type="number"
            autoComplete="one-time-code"
            inputMode="numeric"
            placeholder={formatMessage({
              id: 'dialog.confirmPhone.code',
              defaultMessage: 'Код из SMS'
            })}
            {...register('code')}
          />
        </FormField>
        <div className="mb-6 flex justify-center">
          {countdown ? (
            <div className="text-secondary-500">
              <FormattedMessage
                defaultMessage="Повторная отправка через {countdown, plural, one {# секунду} few {# секунды} many {# секунд}}"
                id="dialog.confirmPhone.resend"
                values={{ countdown }}
              />
            </div>
          ) : (
            <div
              className="cursor-pointer text-primary-600"
              onClick={handleResend}
            >
              <FormattedMessage
                {...{
                  id: 'button.resend',
                  defaultMessage: 'Отправить повторно'
                }}
              />
            </div>
          )}
        </div>
        <FormActions className="grid-cols-1">
          <Button {...{ isLoading }}>
            <FormattedMessage
              {...{
                id: 'button.confirm',
                defaultMessage: 'Подтвердить'
              }}
            />
          </Button>
        </FormActions>
      </Form>
    </Dialog>
  )
}

export default ConfirmPhoneDialog
