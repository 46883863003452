import React, { PropsWithChildren } from 'react'

import clsx from 'clsx'

import Icon from '@/components/Icon'

interface SelectArrowProps {
  open: boolean
  className?: string
}

const SelectArrow = ({
  className,
  open
}: PropsWithChildren<SelectArrowProps>) => {
  return (
    <Icon
      className={clsx(
        'h-5 w-4 text-secondary-500 transition-transform duration-300',
        open && '[transform:rotateZ(180deg)]',
        className
      )}
      name="arrow-down-16"
    />
  )
}

export default SelectArrow
