import React, { PropsWithChildren } from 'react'

import clsx from 'clsx'

const FormActions = ({
  className,
  children
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <div className={clsx('grid gap-2 tablet:static', className)}>
      {children}
    </div>
  )
}

export default FormActions
