import Link from 'next/link'
import React, { Fragment } from 'react'

import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'

import { Menu, Transition } from '@headlessui/react'

import useRenderSide from '@/hooks/auth/useRenderSide'
import useAuthStore from '@/hooks/stores/useAuthStore'
import useUserInfo from '@/hooks/users/useUserInfo'

import Icon from '@/components/Icon'
import Rule from '@/components/Rule'
import RowSkeleton from '@/components/skeletons/RowSkeleton'

const GENERAL_MENU_OPTIONS = [
  {
    link_id: 'page.dashboard',
    title: 'Дашборд',
    href: '/dashboard',
    icon: 'template-16',
    disabled: false
  },
  {
    link_id: 'page.farm',
    title: 'Моя ферма',
    href: '/farm',
    icon: 'server-16',
    disabled: false
  },
  {
    link_id: 'page.shop',
    title: 'Магазин',
    href: '/shop',
    icon: 'shopping-cart-16',
    disabled: false
  }
]

const ACCOUNT_MENU_OPTIONS = [
  {
    link_id: 'page.finances',
    title: 'Финансы',
    href: '/finances',
    icon: 'cash-16',
    disabled: false
  },
  {
    link_id: 'page.settings',
    title: 'Настройки',
    href: '/settings',
    icon: 'settings-16',
    disabled: false
  },
  {
    link_id: 'page.support',
    title: 'Помощь',
    href: '/support',
    icon: 'support-16',
    disabled: false
  }
]

// const PARTNER_MENU_OPTIONS = [
//   {
//     link_id: 'page.partnerProgram',
//     title: 'Партнерская программа',
//     href: '/partner',
//     icon: 'users-16',
//     disabled: false
//   }
// ]

const MENU_OPTIONS = [
  { options: GENERAL_MENU_OPTIONS },
  {
    options: ACCOUNT_MENU_OPTIONS,
    title: {
      id: 'page.account',
      defaultMessage: 'Аккаунт'
    }
  }
  // { options: PARTNER_MENU_OPTIONS }
]

const AccountMenu = ({ className }: { className?: string }) => {
  const { isDemo, setIsDemo } = useAuthStore()
  const { supportId, isLoading } = useUserInfo()
  const { isBrowser } = useRenderSide()

  return (
    <Menu className={clsx('relative z-40 select-none', className)} as="div">
      <Menu.Button
        className={clsx(
          'flex items-center justify-between gap-3 rounded-x-xl bg-slate-100',
          'px-2 py-1.5 tablet:rounded-xl tablet:px-3 tablet:py-2'
        )}
      >
        {isBrowser && (
          <span className="leading-x-leading relative top-[1px] text-x-base">
            {isDemo ? (
              <FormattedMessage
                {...{ id: 'demo.title.short', defaultMessage: 'Демо' }}
              />
            ) : isLoading ? (
              <RowSkeleton className="h-[22px] w-10" />
            ) : (
              supportId
            )}
          </span>
        )}
        <Icon className="h-6 w-6 text-secondary-500" name="menu-24" />
      </Menu.Button>
      <Transition.Child
        as={Fragment}
        enter="duration-150 ease-in"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="duration-100 ease-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Menu.Items
          className={clsx(
            'absolute right-0 top-[calc(100%+8px)] z-40 flex w-[256px]',
            'flex-col overflow-hidden rounded-xl bg-white shadow-md'
          )}
        >
          {MENU_OPTIONS.map(({ title, options }, index, array) => (
            <Fragment key={index}>
              <div className="flex flex-col bg-white px-2.5 py-3">
                {title && (
                  <h3 className="flex min-h-10 items-center px-2 py-2 text-x-base font-medium leading-x-base">
                    <FormattedMessage {...{ ...title }} />
                  </h3>
                )}
                {options.map(({ icon, title, href, link_id, disabled }) => (
                  <Menu.Item disabled={disabled} key={link_id}>
                    {({ active }) => (
                      <Link
                        className={clsx(
                          'flex min-h-10 items-center gap-2.5 px-2.5 py-2',
                          active && 'font-medium',
                          disabled
                            ? 'cursor-default text-secondary-500'
                            : 'cursor-pointer'
                        )}
                        href={href}
                      >
                        {!!icon && (
                          <Icon
                            className="mb-0.5 h-5 w-5 text-secondary-500"
                            name={icon}
                          />
                        )}
                        <span className="text-x-base leading-x-base">
                          <FormattedMessage
                            {...{ id: link_id, defaultMessage: title }}
                          />
                        </span>
                      </Link>
                    )}
                  </Menu.Item>
                ))}
              </div>
              {index < array.length - 1 && <Rule />}
            </Fragment>
          ))}
          <Rule />
          <DemoSwitchOption isDemo={isDemo} setIsDemo={setIsDemo} />
        </Menu.Items>
      </Transition.Child>
    </Menu>
  )
}

export default AccountMenu

const DemoSwitchOption = ({
  isDemo,
  setIsDemo
}: {
  isDemo: boolean
  setIsDemo: (value: boolean) => void
}) => {
  const toggleDemo = () => {
    setIsDemo(!isDemo)
  }

  return (
    <Menu.Item>
      {({ active }) => (
        <div
          className={clsx(
            'flex min-h-10 cursor-pointer items-center gap-2.5 px-[22px] py-5',
            active && 'font-medium'
          )}
          onClick={toggleDemo}
        >
          <span className="text-x-base leading-x-base text-primary-600">
            {isDemo ? (
              <FormattedMessage
                {...{
                  id: 'button.demo.exit',
                  defaultMessage: 'Выйти из демо-кабинета'
                }}
              />
            ) : (
              <FormattedMessage
                {...{
                  id: 'button.demo.login',
                  defaultMessage: 'Войти в демо-кабинет'
                }}
              />
            )}
          </span>
          <Icon className="h-4 w-4 text-primary-600" name="arrow-right-v2-16" />
        </div>
      )}
    </Menu.Item>
  )
}
