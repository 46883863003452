import React, { Fragment, PropsWithChildren, Ref, forwardRef } from 'react'

import clsx from 'clsx'
import { find, isEqual } from 'lodash'
import { useIntl } from 'react-intl'

import { flip, shift, useFloating } from '@floating-ui/react'
import { Listbox, Transition } from '@headlessui/react'

import SelectBoxButton from '@/components/selectbox/SelectBoxButton'
import SelectOption from '@/components/selectbox/SelectOption'

export interface SelectOption {
  id?: number | string
  title: string | JSX.Element
  value: any
  disabled?: boolean
}

interface SelectProps {
  placeholder?: string
  options: SelectOption[]
  disabled?: boolean
  className?: string
  buttonClassName?: string
  onChange?: (value: any) => void
  defaultValue?: any
  name?: string
  icon?: string
  value: any
  displayArrow?: boolean
  isLoading?: boolean
  variant?: 'default' | 'form'
  hasResetButton?: boolean
  onReset?: () => void
}

const SelectBox = (
  {
    isLoading,
    placeholder,
    options,
    disabled,
    className,
    buttonClassName,
    onChange,
    name,
    defaultValue,
    icon,
    children,
    value,
    hasResetButton,
    onReset,
    variant = 'default',
    displayArrow = true
  }: PropsWithChildren<SelectProps>,
  ref: Ref<HTMLDivElement>
) => {
  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [flip(), shift({ crossAxis: true })]
  })

  const onChangeHandler = (value: any) => {
    if (onChange) {
      onChange({ target: { value, name } })
    }
  }

  const { formatMessage } = useIntl()

  const selectedOption = find(options, { value })
  const hasValue = !!value || selectedOption?.value === false
  const displayedValue =
    hasValue || children
      ? children ||
        (typeof selectedOption?.title === 'string' &&
          selectedOption?.id &&
          formatMessage({
            id: selectedOption?.id as string,
            defaultMessage: selectedOption?.title as string
          })) ||
        selectedOption?.title ||
        placeholder
      : placeholder

  return (
    <div
      className={clsx(
        'select-none',
        disabled && 'pointer-events-none opacity-50',
        className
      )}
    >
      <Listbox
        as={Fragment}
        defaultValue={defaultValue}
        onChange={onChangeHandler}
        ref={ref}
        value={value || null}
      >
        <div className="relative">
          <SelectBoxButton
            {...{
              variant,
              isLoading,
              hasResetButton,
              onReset,
              className: buttonClassName,
              selectedOption,
              icon,
              displayArrow,
              as: Listbox.Button,
              ref: refs.setReference
            }}
          >
            {displayedValue}
          </SelectBoxButton>
          <Transition
            as={Fragment}
            enter="transition-opacity duration-100 ease-in"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-100 ease-out"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={clsx(
                'scroll-wrap absolute z-20 my-2 max-h-[260px] w-max min-w-[100%] max-w-[480px]',
                'overflow-x-hidden overflow-y-scroll rounded-md bg-white py-1 text-lg shadow-lg sm:text-sm'
              )}
              ref={refs.setFloating}
              style={floatingStyles}
            >
              {options?.map((option, index) => (
                <SelectOption
                  {...{
                    ...option,
                    as: Listbox.Option,
                    ...(!option.value && {
                      isSelected: isEqual(option.value, selectedOption?.value)
                    })
                  }}
                  key={index}
                />
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

export default forwardRef(SelectBox)
